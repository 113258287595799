import styled from 'styled-components';

export const Button = styled.button`
  padding-right: 14px;
  display: flex;
  align-items: center;
  width: 354px;
  height: 46px;
  background: var(--background);

  &.forMobile {
    width: 100%;
    height: 62px;

    p {
      width: auto;
    }

    .iconCategory {
      width: 76px;
    }

    .iconArrow {
      position: absolute;
      right: 16px;
      top: 24px;
      margin: 0;
      transition: transform 0.2s linear;
      transform: rotate(90deg);

      &.open {
        transform: rotate(270deg);
      }
    }
  }

  &.active,
  &:focus,
  &:hover {
    background: #f4f5f9;
  }

  .iconCategory {
    width: 92px;
    display: flex;
    justify-content: center;
  }

  p {
    width: 245px;
    text-align: start;
    color: var(--text-color);
  }

  .iconArrow {
    margin-left: 20px;
  }

  .iconCategory svg {
    transform: scale(0.5);
  }

  @media screen and (max-width: 360px) {
    width: 342px;
    p {
      width: 204px;
    }
  }

  @media screen and (max-width: 340px) {
    width: 328px;
    p {
      width: 196px;
    }
  }

  @media screen and (max-width: 328px) {
    width: 310px;
    p {
      width: 162px;
    }
  }
`;
