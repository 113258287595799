import React, { lazy, StrictMode, Suspense, useLayoutEffect, useRef, useState } from 'react';
import { GlobalStyle } from 'assets/globalStyle';
import { theme } from 'assets/theme';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { store } from 'store';
import { ThemeProvider } from 'styled-components';

import { AppHelmet } from './components/AppHelmet';
import { Loader } from './components/Loader';
import { ScrollToTop } from './components/ScrollToTop';
import { useRefDimensions } from './hooks/useRefDimensions';
import { useResize } from './hooks/useResize';
import { Redirect } from './utils/Redirect';
import { Footer } from './view/Footer';
import { Header } from './view/Header';
import { Cookies } from './view/modals/Cookies';
import { Services } from './services';

import './App.css';

const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ './pages/Home').then((module) => ({
    default: module.Home,
  })),
);
const Search = lazy(() =>
  import(/* webpackChunkName: "search" */ './pages/Search').then((module) => ({
    default: module.Search,
  })),
);
const Favorites = lazy(() =>
  import(/* webpackChunkName: "favorites" */ './pages/Favorites').then((module) => ({
    default: module.Favorites,
  })),
);
const Compare = lazy(() =>
  import(/* webpackChunkName: "compare" */ './pages/Compare').then((module) => ({
    default: module.Compare,
  })),
);
// const History = lazy(() => import(/* webpackChunkName: "history" */ './pages/History'));
const SocialProviderAuth = lazy(
  () => import(/* webpackChunkName: "socialproviderauth" */ './pages/Auth/SocialProviderAuth'),
);
const EmailConfirm = lazy(() =>
  import(/* webpackChunkName: "emailconfirm" */ './pages/EmailConfirm/EmailConfirm').then(
    (module) => ({ default: module.EmailConfirm }),
  ),
);
const Articles = lazy(() =>
  import(/* webpackChunkName: "articles" */ './pages/Articles').then((module) => ({
    default: module.Articles,
  })),
);
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "resetpassword" */ './pages/ResetPassword').then((module) => ({
    default: module.ResetPassword,
  })),
);
const NotFoundPage = lazy(() =>
  import(/* webpackChunkName: "notfound" */ './pages/Services/NotFound').then((module) => ({
    default: module.NotFoundPage,
  })),
);
const Product = lazy(() =>
  import(/* webpackChunkName: "product" */ './pages/Product').then((module) => ({
    default: module.Product,
  })),
);
const Category = lazy(() =>
  import(/* webpackChunkName: "category" */ './pages/Category').then((module) => ({
    default: module.Category,
  })),
);
const Article = lazy(() =>
  import(/* webpackChunkName: "article" */ './pages/Article').then((module) => ({
    default: module.Article,
  })),
);

const App = () => {
  const [, height] = useResize();
  const rootElem = useRef<HTMLDivElement>(null);
  const footer = useRef<HTMLElement>(null);
  const { height: rootHeight } = useRefDimensions(rootElem);
  const { height: footerHeight } = useRefDimensions(footer);
  const [isLayoutSmallerThanViewportHeight, setIsLayoutSmallerThanViewportHeight] = useState(false);

  useLayoutEffect(() => {
    const contentHeight = isLayoutSmallerThanViewportHeight
      ? rootHeight + footerHeight
      : rootHeight;
    if (rootElem.current && rootHeight !== 0 && contentHeight < height) {
      setIsLayoutSmallerThanViewportHeight(true);
    } else {
      setIsLayoutSmallerThanViewportHeight(false);
    }
  }, [footerHeight, height, rootHeight]);

  return (
    <StrictMode>
      <Router>
        <Suspense fallback={<Loader />}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <div ref={rootElem}>
                <AppHelmet />
                <Header />
                <main>
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/s' element={<Search />} />
                    <Route path='/item/:slug' element={<Redirect to='/item-:slug' />} />
                    <Route path='/favorites' element={<Favorites />} />
                    <Route path='/compare' element={<Compare />} />
                    {/*<Route path='/history' element={<History />} />*/}
                    <Route path='/category/:id/*' element={<Redirect to='/category-:id' />} />
                    <Route path='/auth/:provider' element={<SocialProviderAuth />} />
                    <Route path='/email-confirm' element={<EmailConfirm />} />
                    <Route
                      path='/article/:rubric?/:slug'
                      element={<Redirect to='/article-:slug' />}
                    />
                    <Route
                      path='/articles/:slug?/:slug1?/:slug2?/:slug3?/:slug4?'
                      element={<Articles />}
                    />
                    <Route path='/reset_password' element={<ResetPassword />} />
                    <Route path='/:path' element={<AdditionalRouter />} />
                    <Route path='*' element={<NotFoundPage />} />
                  </Routes>
                </main>
                <Footer ref={footer} fixedToTheBottom={isLayoutSmallerThanViewportHeight} />
                <ScrollToTop />
                <Cookies />
                <Services />
              </div>
            </ThemeProvider>
          </Provider>
        </Suspense>
      </Router>
    </StrictMode>
  );
};

const AdditionalRouter = () => {
  const { path = '' } = useParams();
  if (path.startsWith('item-')) {
    return <Product params={{ slug: path.split('item-')[1] }} />;
  }
  if (path.startsWith('category-')) {
    return <Category params={{ id: path.split('category-')[1] }} />;
  }
  if (path.startsWith('article-')) {
    return <Article params={{ slug: path.split('article-')[1] }} />;
  }
  return <NotFoundPage />;
};

export default App;
