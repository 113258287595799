import { btnNetworksSettings } from './config';
import { ButtonHTML, CorporateIconBtn } from './styles';
import { ButtonType } from './types';

import './styles.css';

type Props = ButtonType;
export const Button = ({
  name,
  className = 'btnDefault',
  children,
  onClick,
  onBlur,
  onChange,
  variant = 'default',
  type,
  corporateType,
  value,
  tabIndex,
}: Props) => {
  const btnStyle = corporateType ? btnNetworksSettings[corporateType] : undefined;
  if (btnStyle) {
    return (
      <CorporateIconBtn>
        <button
          className={`${btnStyle.className} corpMainStyle`}
          type={type}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          value={value}
        >
          {btnStyle.icon}
          {name}
        </button>
      </CorporateIconBtn>
    );
  }
  return (
    <ButtonHTML
      variant={variant}
      tabIndex={tabIndex}
      className={className}
      type={type}
      onClick={onClick}
      onBlur={onBlur}
    >
      {name}
      {children}
    </ButtonHTML>
  );
};
