// @ts-ignore
import cross24 from 'assets/images/btnIcons/cross24.svg?url';
import styled from 'styled-components';

export const StyledMenu = styled.section`
  top: 0;
  right: 0;
  position: absolute;
  z-index: 5;
  flex-direction: column;
  display: flex;
  align-items: end;
  max-width: 360px;
  width: 100%;
  background: var(--background);
  max-height: 100vh;
  overflow-y: scroll;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  background: center center url(${cross24}) no-repeat;
  cursor: pointer;
  z-index: 1;
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  width: 100%;
  gap: 32px;

  @media screen and (max-width: 480px) {
    gap: 12px;
  }

  .logo {
    svg {
      scale: 1.2;
      cursor: pointer;
      transform-origin: left 50%;

      @media screen and (max-width: 960px) {
        scale: 0.8;
      }
      @media screen and (max-width: 360px) {
        scale: 0.6;
      }
    }
  }

  @media screen and (max-width: 480px) {
    &.isSearchFocused {
      grid-template-columns: auto max-content;
      .logo {
        display: none;
      }
    }
  }
`;

export const StyledSearch = styled.div`
  position: absolute;
  top: 114px;
  left: 50%;
  right: 50%;

  @media screen and (max-width: 400px) {
    input {
      width: 273px;
    }
  }

  @media screen and (max-width: 372px) {
    input {
      width: 246px;
    }
  }
  @media screen and (max-width: 346px) {
    input {
      width: 222px;
    }
  }
`;

export const StyledWrapperUser = styled.div`
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid #d9dce9;
  margin-bottom: 6px;
`;

export const StyledWrapperCategory = styled.ul`
  padding: 12px 20px;

  li {
    margin-bottom: 10px;
  }

  a {
    text-align: left;
  }
`;

export const StyledCategoryButton = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    border-bottom: 1px solid #d9dce9;
    height: 0;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;
