import { call, put, takeEvery } from 'redux-saga/effects';

import { postLogin } from '../../api/login';
import { identityUpdate } from '../identity';
import { ERROR, SUCCESS } from '../index';

import { toggleLoginModal } from './index';
import { actionTypes } from './types';

export function* postLoginSaga(action) {
  try {
    const data = yield call(postLogin, action.payload);
    yield put(toggleLoginModal(false));
    yield put(
      identityUpdate({
        isAuthorised: true,
        email: data.email,
        name: data.name,
        authorization: {
          token: data.accessToken,
        },
        role: data.role,
        permissions: data.permissions,
        isAdmin: data.role === 'admin',
        isNeededEmailConfirmation: !data.email_verified_at,
      }),
    );
    yield put({ type: actionTypes.POST_LOGIN + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_LOGIN + ERROR, payload: error });
  }
}

export function* watchLoginSaga() {
  yield takeEvery(actionTypes.POST_LOGIN, postLoginSaga);
}
