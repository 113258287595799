/**
 * Функция извлекает значения из объекта по пути
 * ['key1', 'key2'] => object.key1.key2
 */

export default function getByPath<Object>(path: string[], obj: Object): object | string;
// eslint-disable-next-line no-redeclare
export default function getByPath<Object, First extends keyof Object>(
  path: [First],
  obj: Object,
): Object[First];
// eslint-disable-next-line no-redeclare
export default function getByPath<
  Object,
  First extends keyof Object,
  Second extends keyof Object[First],
>(path: [First, Second], obj: Object): Object[First][Second];
// eslint-disable-next-line no-redeclare
export default function getByPath<
  Object,
  First extends keyof Object,
  Second extends keyof Object[First],
  Third extends keyof Object[First][Second],
>(path: [First, Second, Third], obj: Object): Object[First][Second][Third];
// eslint-disable-next-line no-redeclare
export default function getByPath<
  Object,
  First extends keyof Object,
  Second extends keyof Object[First],
  Third extends keyof Object[First][Second],
  Fourth extends keyof Object[First][Second][Third],
>(path: [First, Second, Third, Fourth], obj: Object): Object[First][Second][Third][Fourth];
// eslint-disable-next-line no-redeclare
export default function getByPath<
  Object,
  First extends keyof Object,
  Second extends keyof Object[First],
  Third extends keyof Object[First][Second],
  Fourth extends keyof Object[First][Second][Third],
  Fifth extends keyof Object[First][Second][Third][Fourth],
>(
  path: [First, Second, Third, Fourth, Fifth],
  obj: Object,
): Object[First][Second][Third][Fourth][Fifth];
// eslint-disable-next-line no-redeclare
export default function getByPath(path: string[], obj: any): any | undefined {
  return (Array.isArray(path) ? path : []).reduce(function (prev, curr) {
    return prev ? prev[curr] : undefined;
  }, obj || {});
}
