import styled, { css } from 'styled-components';

interface IButton {
  variant: 'default' | 'stroke' | 'primary' | 'secondary';
}

export const CorporateIconBtn = styled.div`
  width: 100%;
`;
// TODO: Moved fs, lh, br and etc. and padding to ButtonHTML after clearfy global website buttons styles
const buttonStyles = {
  default: css``,
  primary: css`
    color: ${(props) => props.theme.colors.white};
    transition: ${(props) => props.theme.transition.primary};
    background-color: var(--color-primary);

    padding: var(--font-s-1) var(--font-s-3);
    font-size: 14px;
    line-height: ${(props) => props.theme.size.size5}px;
    border-radius: var(--radius-1);

    &:hover {
      color: ${(props) => props.theme.colors.white};
      transition: ${(props) => props.theme.transition.primary};
      background-color: var(--color-primary-hover);
    }
  `,
  secondary: css`
    background-color: var(--color-third);
    color: ${(props) => props.theme.colors.white};
    transition: ${(props) => props.theme.transition.primary};

    padding: var(--font-s-1) var(--font-s-3);
    font-size: 14px;
    line-height: ${(props) => props.theme.size.size5}px;

    &:hover {
      transition: ${(props) => props.theme.transition.primary};
      background-color: var(--color-third-hover);
    }
  `,
  stroke: css`
    color: ${(props) => props.theme.colors.dark};
    font-size: 14px;
    line-height: ${(props) => props.theme.size.size5}px;
    padding: ${(props) => props.theme.size.size2}px ${(props) => props.theme.size.size6}px;
    transition: ${(props) => props.theme.transition.primary};
    border-radius: ${(props) => props.theme.size.size1}px;
    border: 1px solid ${(props) => props.theme.colors.third};
    background: none;

    &:hover,
    &:active {
      transition: ${(props) => props.theme.transition.primary};
      border-color: ${(props) => props.theme.colors.behavioral};
    }
  `,
};

export const ButtonHTML = styled.button<IButton>`
  ${({ variant }) => buttonStyles[variant] || buttonStyles.default}
`;
