import {
  ForgotPasswordBody,
  ForgotPasswordDataType,
  ForgotPasswordErrorType,
} from '../../api/forgotPassword';

export const actionTypes = {
  POST_FORGOT_PASSWORD: 'POST_FORGOT_PASSWORD',
};

export type ForgotPasswordState = {
  isRequested: boolean;
  requestBody?: ForgotPasswordBody;
  responseBody?: ForgotPasswordDataType;
  errors?: ForgotPasswordErrorType;
};
