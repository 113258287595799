/**
 * Функция удаляет key в объекте по пути
 * ['key1', 'key2'] => object.key1.key2
 * возвращает мутированный объект
 */

export default function removeByPath(path, obj) {
  (Array.isArray(path) ? path : []).reduce(function (prev, curr, index, arr) {
    if (index === arr.length - 1) {
      delete prev[curr];
    } else {
      prev[curr] = typeof prev[curr] === 'object' ? prev[curr] : {};
    }

    return prev[curr];
  }, obj || {});

  return obj;
}
