import { AnyAction } from 'redux';

import { ArticlesQueryOptions } from '../../api/articles';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = {
  articles: null,
  article: null,
  rubrics: null,
  errors: null,
};

export const articles = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_ARTICLES}${SUCCESS}`: {
      return { ...state, articles: action.payload };
    }
    case `${actionTypes.GET_ARTICLE}${SUCCESS}`: {
      return { ...state, article: action.payload };
    }
    case `${actionTypes.GET_ARTICLE}${ERROR}`: {
      return { ...state, errors: action.payload.response };
    }
    case `${actionTypes.GET_RUBRICS}${SUCCESS}`: {
      return { ...state, rubrics: action.payload };
    }
    default:
      return state;
  }
};

export const getArticles = (queryOptions: ArticlesQueryOptions = {}) => ({
  type: `${actionTypes.GET_ARTICLES}`,
  queryOptions,
});

export const getArticle = (slug: string) => ({
  type: `${actionTypes.GET_ARTICLE}`,
  slug,
});

export const getRubrics = () => ({
  type: `${actionTypes.GET_RUBRICS}`,
});
