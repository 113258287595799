import { AnyAction } from 'redux';

import { ForgotPasswordBody } from '../../api/forgotPassword';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, ForgotPasswordState } from './types';

const initialState: ForgotPasswordState = {
  isRequested: false,
};

export const forgotPassword = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.POST_FORGOT_PASSWORD}`: {
      return {
        ...state,
        isRequested: true,
        requestBody: action.payload,
        responseBody: undefined,
        errors: undefined,
      };
    }
    case `${actionTypes.POST_FORGOT_PASSWORD}${SUCCESS}`: {
      return {
        ...state,
        isRequested: false,
        responseBody: { ...action.payload, success: true },
        errors: undefined,
      };
    }
    case `${actionTypes.POST_FORGOT_PASSWORD}${ERROR}`: {
      return { ...state, isRequested: false, responseBody: undefined, errors: action.payload };
    }
    default:
      return state;
  }
};

export const postForgotPassword = (body: ForgotPasswordBody) => ({
  type: `${actionTypes.POST_FORGOT_PASSWORD}`,
  payload: body,
});
