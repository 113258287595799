import { AnyAction } from 'redux';

import { ComplaintBody } from '../../api/complaint';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, ComplaintState } from './types';

const initialState: ComplaintState = {
  isRequested: false,
};

export const complaint = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.DELETE_COMPLAINT}`:
    case `${actionTypes.POST_COMPLAINT}`: {
      return { ...state, isRequested: true, requestBody: action.payload };
    }
    case `${actionTypes.DELETE_COMPLAINT}${SUCCESS}`:
    case `${actionTypes.POST_COMPLAINT}${SUCCESS}`: {
      return { ...state, isRequested: false };
    }
    case `${actionTypes.DELETE_COMPLAINT}${ERROR}`:
    case `${actionTypes.POST_COMPLAINT}${ERROR}`: {
      return { ...state, isRequested: false };
    }
    default:
      return state;
  }
};

export const postComplaint = (body: ComplaintBody) => ({
  type: `${actionTypes.POST_COMPLAINT}`,
  payload: body,
});

export const deleteComplaint = (body: ComplaintBody) => ({
  type: `${actionTypes.DELETE_COMPLAINT}`,
  payload: body,
});
