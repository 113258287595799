import Apple from '../../assets/images/socialNetwork/apple.svg';
import Facebook from '../../assets/images/socialNetwork/facebook.svg';
import Google from '../../assets/images/socialNetwork/google.svg';
import OK from '../../assets/images/socialNetwork/Odnoklassniki.svg';
import VK from '../../assets/images/socialNetwork/vk.svg';
export const btnNetworksSettings = {
  vkontakte: {
    icon: <VK />,
    className: 'vkBtn',
  },
  google: {
    icon: <Google />,
    className: 'vkGoogle',
  },
  apple: {
    icon: <Apple />,
    className: 'vkApple',
  },
  facebook: {
    icon: <Facebook />,
    className: 'vkFacebook',
  },
  odnoklassniki: {
    icon: <OK />,
    className: 'vkOk',
  },
};
