import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { btnNetworksSettings } from './config';
import { LinkType } from './types';

import './styles.css';

export const Link = ({
  name,
  image,
  href,
  className,
  target,
  corporateType,
  itemProp,
  children,
  onClick,
  rel,
  title,
  tabIndex,
}: LinkType) => {
  const navigate = useNavigate();
  const btnStyle = corporateType ? btnNetworksSettings[corporateType] : undefined;
  const linkClasses = classNames(className, 'linkItem', btnStyle?.className, {
    corpMainStyle: btnStyle,
  });
  // отображение внутренней части линки
  const linkContext = useMemo(() => {
    if (children) {
      return children;
    }
    if (btnStyle) {
      return (
        <>
          {btnStyle.icon}
          <span itemProp='name'>{name}</span>
        </>
      );
    }
    if (image && !name) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{image}</>;
    }
    if (!image && name) {
      return <span itemProp='name'>{name}</span>;
    }
    if (image && name) {
      return (
        <div>
          <div className='linkItemImage'>{image}</div>
          <span itemProp='name'>{name}</span>
        </div>
      );
    }
  }, [btnStyle, children, image, name]);

  const handleClick = useCallback(
    (e) => {
      if (typeof onClick === 'function') return onClick(e);
      if (
        typeof href !== 'undefined' &&
        href !== null &&
        !href.trim().startsWith('mailto:') &&
        !href.trim().startsWith('http://') &&
        !href.trim().startsWith('https://') &&
        target !== '_blank'
      ) {
        e.preventDefault();
        e.stopPropagation();
        navigate(href);
        return false;
      }
      return true;
    },
    [href, navigate, onClick, target],
  );

  return (
    <a
      href={href}
      className={linkClasses}
      target={target}
      rel={rel}
      title={title ?? name ?? undefined}
      itemProp={itemProp}
      onClick={handleClick}
      tabIndex={tabIndex}
    >
      {linkContext}
    </a>
  );
};
