import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
    font-display: fallback;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    border: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  :focus,
  :active,
  a:focus,
  a:active {
    outline: none;
  }

  main {
    min-height: 50vh;
  }

  html,
  body {
    font-size: 14px;
    font-weight: 400;
  }

  button {
    cursor: pointer;
  }
  
  input::-ms-clear {
    display: none;
  }
  
  a,
  a:visited,
  a:hover {
    text-decoration: none;
  }
  
  ul li,
  ol li {
    list-style: none;
  }
  
  img {
    vertical-align: top;
  }
  
  .withMargins {
    margin: ${theme.size.size2}px 0;
  }  

  .container{
    width: 100%;
    max-width: calc(${theme.containers.xxl}px + 100px);
    margin: 0 auto;
    padding: 0 50px;
    box-sizing: border-box;

    @media screen and (max-width: ${theme.containers.lg}px) {
      padding: 0 ${theme.size.size6}px;
    } 

    @media screen and (max-width: ${theme.containers.md}px) {
      padding: 0 ${theme.size.size4}px;
    } 
  }

  h1,
  .h1 {
    font-weight: ${theme.weight.bold};
    font-size: ${theme.size.size10}px;
    line-height: 46.88px;
  }

  h2,
  .h2 {
    font-weight: ${theme.weight.bold};
    font-size: ${theme.size.size8}px;
    line-height: 37.5px;
  }

  h3,
  .h3 {
    font-weight: ${theme.weight.bold};
    font-size: ${theme.size.size6}px;
    line-height: 28.13px;
  }

  h4,
  .h4 {
    font-weight: ${theme.weight.bold};
    font-size: 18px;
    line-height: 21.09px;
  }

  h5,
  .h5 {
    font-weight: ${theme.weight.bold};
    font-size: ${theme.size.size4}px;
    line-height: 18.75px;
  }

  h6,
  .h6 {
    font-weight: ${theme.weight.bold};
    font-size: 14px;
    line-height: ${theme.size.size4}px;
  }

  .content{
    & > * {
      & + h2 {
        margin-top: 34px;
      }
  
      & + h3 {
        margin-top: 64px;
      }
  
      & + h4 {
        margin-top: ${theme.size.size6}px;
      }
  
      & + blockquote {
        margin-top: 40px;
  
        @media screen and (max-width: 960px) {
          margin-top: 80px;
        }
  
        @media screen and (max-width: 480px) {
          margin-top: 56px;
        }
      }
    }
  
    div,
    p {
      font-size: ${theme.size.size4}px;
      line-height: ${theme.size.size6}px;
      color: ${theme.colors.dark};
    }
  
    div {
      margin-top: 40px;
  
      &:first-child {
        margin-top: 0;
      }
    }
  
    p {
      margin-bottom: ${theme.size.size4}px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    figure {
      margin: 120px auto;
      text-align: center;
  
      @media screen and (max-width: 960px) {
        margin: 80px 0;
      }
  
      @media screen and (max-width: 480px) {
        margin: 56px 0;
      }
  
      figcaption {
        width: 100%;
        margin-top: ${theme.size.size2}px;
        font-size: 14px;
        line-height: ${theme.size.size4}px;
        text-align: center;
        color: ${theme.colors.third};
      }
    }
  
    img {
      max-width: 100%;
  
      &.image-style-align-left {
        float: left;
        margin-right: 30px;
        margin-bottom: ${theme.size.size3}px;
      }
      &.image-style-align-right {
        float: right;
        margin-left: 30px;
      }
    }
  
    ol,
    ul {
      padding-left: ${theme.size.size6}px;
      margin-bottom: 40px;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      li {
        margin-bottom: ${theme.size.size4}px;
        font-size: ${theme.size.size4}px;
        line-height: ${theme.size.size6}px;
        color: var(--text-color) ${theme.colors.dark};
        font-weight: 400;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  
    ol {
      li {
        list-style: auto;
      }
    }
  
    ul {
      li {
        position: relative;
        list-style: none;
  
        &:before {
          position: absolute;
          content: '';
          display: block;
          width: ${theme.size.size1}px;
          height: ${theme.size.size1}px;
          top: 10px;
          left: -14px;
          border-radius: 50%;
          background-color: ${theme.colors.dark1c};
        }
      }
    }
  
    blockquote {
      margin-bottom: 40px;
      margin-left: 120px;
      margin-right: 120px;
      padding: ${theme.size.size4}px 0 ${theme.size.size4}px ${theme.size.size4}px;
      font-size: ${theme.size.size4}px;
      line-height: ${theme.size.size6}px;
      color: ${theme.colors.dark};
      border-left: 2px solid ${theme.colors.error};
  
      @media screen and (max-width: 960px) {
        margin-bottom: 80px;
      }
  
      @media screen and (max-width: 768px) {
        margin-left: ${theme.size.size6}px;
        margin-right: ${theme.size.size6}px;
      }
  
      @media screen and (max-width: 480px) {
        margin-bottom: 56px;
        margin-left: ${theme.size.size2}px;
        margin-right: ${theme.size.size2}px;
      }
  
      &:last-child {
        margin-top: 0;
      }
    }
    h1 {
      margin-bottom: ${theme.size.size6}px;
      font-size: 40px;
      line-height: 46.88px;
      font-weight: 700;
    }
    h2 {
      font-size: ${theme.size.size8}px;
      line-heght: 37.5px;
  
      & + * {
        margin-top: ${theme.size.size6}px;
      }
    }
    h3 {
      font-size: ${theme.size.size6}px;
      line-heght: 28.13px;
  
      & + * {
        margin-top: ${theme.size.size4}px;
      }
    }
    h4 {
      font-size: 18px;
      line-heght: 21.09px;
  
      & + * {
        margin-top: ${theme.size.size4}px;
      }
    }
  }

`;
