import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = { items: [] };

export const recentlyViewedProducts = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_RECENTLY_VIEWED_PRODUCTS}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const getRecentlyViewedProducts = () => ({
  type: `${actionTypes.GET_RECENTLY_VIEWED_PRODUCTS}`,
});
