import styled, { css } from 'styled-components';

import { ButtonIcon } from '../ButtonIcon';

export const ModalWrapper = styled.div<{ isShow: boolean }>`
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 6px 12px rgba(52, 74, 153, 0.1);
  display: flex;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.isShow
      ? css`
          overflow-y: auto;
          opacity: 1;
          pointer-events: visible;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          pointer-events: none;
          transform: translateY(-200px);
          transition: 0.3s ease-in-out;
          transition-property: opacity, transform;
        `}
`;

export const ModalContent = styled.div`
  width: 482px;
  padding: 40px;
  height: fit-content;
  background-color: var(--background);
  cursor: auto;
  border-radius: 16px;

  .forFilter & {
    border-radius: 0;
    padding: 8px 16px 0 16px;
    font-size: 16px;
  }

  @media screen and (max-width: 482px) {
    width: 100%;
  }
`;

export const ModalTitle = styled.div``;
export const TitleHeader = styled.div`
  margin: 10px 0 10px 0;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  .forFilter & div {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const TitleDescription = styled.div`
  margin: 10px 0 10px 0;
`;
export const ModalBody = styled.div`
  padding: 0 0 10px 0;

  .forFilter & {
    padding: 0 16px 8px 16px;
    background: #f4f5f9;
    margin-left: -16px;
    margin-right: -16px;
  }
`;

export const ModalHeaderFooter = styled.div`
  padding: 0;
  margin: 0;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
  transform: scale(1.2);

  &:hover,
  &:focus {
    filter: invert(20%) sepia(96%) saturate(6210%) hue-rotate(358deg) brightness(112%)
      contrast(124%);
  }
`;
