import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/translation.json';
import rus from './locales/ru/translation.json';

export const defaultNS = 'rus';

export const resources = {
  en: {
    en,
  },
  rus: {
    rus,
  },
};

i18next.use(initReactI18next).init({
  lng: 'rus', // if you're using a language detector, do not define the lng option
  debug: false,
  resources,
  defaultNS,
  returnNull: false,
});
