import React, { useCallback, useState } from 'react';
import Cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import { Link } from '../../../components/Link';
import { Text } from '../../../components/Text';

import { StyledCookies, WrapperBtn } from './styles';

const ALLOWED_COOKIES = 'allowed_cookies';

export const Cookies = () => {
  const { t } = useTranslation();
  const [isCookiesAllowed, setIsCookiesAllowed] = useState(!!Cookie.get(ALLOWED_COOKIES));

  const onClose = useCallback(() => {
    Cookie.set(ALLOWED_COOKIES, 'true', { expires: 90 });
    setIsCookiesAllowed(true);
  }, []);

  if (isCookiesAllowed) return null;

  return (
    <StyledCookies>
      <Text.Text18 color='white'>{t('cookies.header')}</Text.Text18>
      <Text.Text16 color='white'>
        {t('cookies.message')}{' '}
        <Link
          href={'/article-politika-konfidenczial-nosti'}
          name={t('misc.more')}
          className={'inline white'}
        />
      </Text.Text16>
      <WrapperBtn>
        <Button className={'corpMainStyle centered'} onClick={onClose} name={'Ok'} />
      </WrapperBtn>
    </StyledCookies>
  );
};
