import React from 'react';

import IconQuestion from '../../assets/favicon/QuestionCircle.svg';
import { Button } from '../Button';
import { Title } from '../Title';
import { Tooltip } from '../Tooltip';

import { DropdownType } from './types';

import './styles.css';

type Props = DropdownType;
export const Accordion = ({
  className,
  classNameButton,
  openDropSection = true,
  placeholder,
  openDropdown,
  classNameButtonAccordionHint,
  title,
  onClick,
  hint,
  tabIndex,
}: Props) => {
  return (
    <>
      {(title || hint) && (
        <div className='sectionFilterCheckboxInfo'>
          {title && <Title.H5>{title}</Title.H5>}
          {hint && (
            <Tooltip content={hint}>
              <button
                className={
                  classNameButtonAccordionHint ? classNameButtonAccordionHint : 'buttonInfo'
                }
              >
                <IconQuestion />
              </button>
            </Tooltip>
          )}
        </div>
      )}
      <div tabIndex={tabIndex} className={className}>
        {openDropSection && (
          <Button
            tabIndex={tabIndex}
            className={classNameButton}
            onClick={onClick}
            name={placeholder}
          >
            {openDropdown ? (
              <i id='iconUP' className='arrow up'></i>
            ) : (
              <i id='iconDown' className='arrow down'></i>
            )}
          </Button>
        )}
      </div>
    </>
  );
};
