import { call, put, takeLeading } from 'redux-saga/effects';

import { getSocialProviders } from '../../api/socialProviders';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getSocialProvidersSaga() {
  try {
    const data = yield call(getSocialProviders);
    yield put({ type: actionTypes.GET_SOCIAL_PROVIDERS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_SOCIAL_PROVIDERS + ERROR, payload: error.response });
  }
}

export function* watchSocialProvidersSaga() {
  yield takeLeading(actionTypes.GET_SOCIAL_PROVIDERS, getSocialProvidersSaga);
}
