import styled from 'styled-components';

import { Text } from '../../../components/Text';

export const WrapperBtn = styled.div`
  margin: 0 0 10px 0;
`;

export const Divider = styled.div`
  text-align: center;
  position: relative;
  margin: 10px 0 15px;

  &:before {
    display: block;
    height: 0;
    width: 100%;
    content: '';
    position: absolute;
    top: 50%;
    border-top: 1px solid #d9d9d9;
  }
`;

export const DividerText = styled.div`
  display: inline-block;
  padding: 0 15px;
  position: relative;
  background-color: var(--background);
`;

export const FooterText = styled(Text.Text12)`
  color: #4f4f4f;
  line-height: 135%;
`;

export const PasswordWrapper = styled.div`
  transition: 0.3s linear;
  transition-property: opacity, height;
  height: 64px;
  overflow: hidden;

  &.hide {
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  position: relative;

  .togglePassword {
    position: absolute;
    right: 21px;
    top: 50%;
    margin-top: -6px;
  }
`;

export const RegisterViaEmailForm = styled.form`
  position: relative;

  &.valid {
  }
  &.invalid {
    input[name='passwordSec'] {
      border-color: red;
    }
  }
`;

export const ErrorMsg = styled.p`
  color: red;
  font-size: 12px;
  line-height: 20px;
`;
