import { AnyAction } from 'redux';

import { FavoriteBody } from '../../api/favorite';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, FavoriteState } from './types';

const initialState: FavoriteState = {
  isRequested: false,
  data: undefined,
  count: undefined,
};

// eslint-disable-next-line complexity
export const favorite = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_FAVORITE}`: {
      return { ...state, isRequested: true, requestBody: undefined };
    }
    case `${actionTypes.GET_FAVORITE}${SUCCESS}`: {
      return {
        ...state,
        isRequested: false,
        data: action.payload,
        count: action.payload.items.length,
      };
    }
    case `${actionTypes.DELETE_FAVORITE}`:
    case `${actionTypes.POST_FAVORITE}`: {
      return { ...state, isRequested: true, requestBody: action.payload };
    }
    case `${actionTypes.DELETE_FAVORITE}${SUCCESS}`: {
      // eslint-disable-next-line prefer-const
      let { data, count } = state;
      if (data && data.items && data.items.length && action.payload.id) {
        data.items = data.items.filter((item) => item.id !== action.payload.id);
      }
      if (typeof count === 'number') {
        count--;
      }
      return { ...state, data, count, isRequested: false };
    }
    case `${actionTypes.POST_FAVORITE}${SUCCESS}`: {
      let { count } = state;
      if (typeof count === 'number') {
        count++;
      }
      return { ...state, count, isRequested: false };
    }
    case `${actionTypes.GET_FAVORITE}${ERROR}`:
    case `${actionTypes.DELETE_FAVORITE}${ERROR}`:
    case `${actionTypes.POST_FAVORITE}${ERROR}`: {
      return { ...state, isRequested: false };
    }
    default:
      return state;
  }
};

export const getFavorite = () => ({
  type: `${actionTypes.GET_FAVORITE}`,
});
export const postFavorite = (body: FavoriteBody) => ({
  type: `${actionTypes.POST_FAVORITE}`,
  payload: body,
});

export const deleteFavorite = (body: FavoriteBody) => ({
  type: `${actionTypes.DELETE_FAVORITE}`,
  payload: body,
});
