import React from 'react';

import { InputType } from './types';

import './styles.css';

type Props = React.HTMLProps<HTMLInputElement> & InputType;
export const Input = ({
  name,
  placeholder,
  onChange,
  value,
  type = 'text',
  className,
  inputRef,
  maxLength,
  required,
  ...rest
}: Props) => {
  return (
    <input
      required={required}
      type={type}
      className={`input ${className}`}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      ref={inputRef}
      maxLength={maxLength}
      {...rest}
    />
  );
};
