import { useCallback, useEffect, useRef } from 'react';

import Cross from '../../assets/images/btnIcons/cross.svg';
import { Title } from '../Title';

import {
  ModalBody,
  ModalContent,
  ModalHeaderFooter,
  ModalTitle,
  ModalWrapper,
  StyledButtonIcon,
  TitleDescription,
  TitleHeader,
} from './styles';
import { ModalType } from './types';
type Props = React.HTMLProps<HTMLDivElement> & ModalType;
export const Modal = ({
  title,
  description,
  isOpen,
  children,
  onClose,
  footer,
  className,
}: Props) => {
  const modalWrapper = useRef(null);
  const handleModalClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !!onClose && onClose();
  }, [onClose]);
  const handleContentClick = useCallback((e) => {
    e.stopPropagation();
  }, []);
  const keyDownEvent = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // eslint-disable-next-line no-magic-numbers
      if (e.keyCode === 27 && onClose) onClose();
    },
    [onClose],
  );

  useEffect(() => {
    // @ts-ignore
    if (isOpen && modalWrapper.current && typeof modalWrapper.current.focus === 'function') {
      // @ts-ignore
      modalWrapper.current.focus();
    }
  }, [isOpen]);

  return (
    <ModalWrapper
      onClick={handleModalClick}
      onKeyDown={keyDownEvent}
      ref={modalWrapper}
      tabIndex={0}
      isShow={isOpen}
      className={className}
    >
      <ModalContent onClick={handleContentClick}>
        <ModalHeaderFooter>
          <ModalTitle>
            <TitleHeader>
              <Title.H4>{title}</Title.H4>
              {onClose && <StyledButtonIcon onClick={onClose} size={'s'} icon={<Cross />} />}
            </TitleHeader>
            {description && <TitleDescription>{description}</TitleDescription>}
          </ModalTitle>
        </ModalHeaderFooter>
        <ModalBody>{children}</ModalBody>
        <ModalHeaderFooter>{footer}</ModalHeaderFooter>
      </ModalContent>
    </ModalWrapper>
  );
};
