import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// @ts-ignore
import ImageMageo from '../../assets/mockImageMageo.png';
import { useDebounce } from '../../hooks/useDebounce';
import { useResize } from '../../hooks/useResize';
import { searchByType } from '../../reducers/filters';
import { selectResultFilters } from '../../reducers/filters/selectors';
import { numberStringToReadingString } from '../../utils/strings';
import { manageMainScroll } from '../../view/HeaderContainer/helper';
import { Link } from '../Link';

import {
  BackButton,
  ButtonsWrap,
  CloseButton,
  Container,
  InputContainer,
  InputSearchStyled,
  LeftWrapper,
  Overlay,
  Results,
  ResultsRow,
  SearchButton,
} from './styles';

const DEBOUNCE_TIMER = 500;

export const encodeSearch = (str: string) =>
  str.toLowerCase().replace('+', '%2B').replace(/\s/g, '+');

interface Props extends React.ComponentProps<'div'> {
  onSearchToggleFocus?: (state: boolean) => void;
}
export const InputSearch = ({ onSearchToggleFocus }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const input: React.RefObject<HTMLInputElement> = useRef(null);
  const inputContainer: React.RefObject<HTMLDivElement> = useRef(null);
  const [screenWidth] = useResize();
  const searchingResult = useSelector(selectResultFilters);
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState<string>(
    searchParams.get('s') ? (searchParams.get('s') as string) : '',
  );
  const coords = useRef({ left: 490, top: 25, width: 960 });
  const withResults = isFocused && !!searchingResult;
  const containerClasses = classNames({ isFocused, withResults });

  const navigateTo = useCallback(
    ({
      pathname,
      search,
    }: {
      pathname?: string;
      search?: { text: string; categoryId?: number };
    }) => {
      if (!pathname && !search) return;
      let path = pathname;
      if (!path) {
        path =
          Array.isArray(searchingResult?.categories) && searchingResult.categories.length === 1
            ? `/category-${
                searchingResult.categories[0]?.slug
                  ? searchingResult.categories[0]?.slug
                  : searchingResult.categories[0]?.id
              }`
            : '/s/';
      }
      const SearchParams = new URLSearchParams();
      if (search?.text.trim()) {
        SearchParams.set('s', search.text.trim());
      }
      if (search?.categoryId) {
        SearchParams.set('category_id', `${search.categoryId}`);
      }

      navigate(`${path}${SearchParams.toString() ? '?' + SearchParams.toString() : ''}`);
    },
    [navigate, searchingResult],
  );

  const toggleFocus = useCallback(
    (state: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !!onSearchToggleFocus && onSearchToggleFocus(state);
      setIsFocused(state);
      if (input.current) {
        if (state) {
          input.current.focus();
        } else {
          input.current.blur();
        }
      }
    },
    [onSearchToggleFocus],
  );

  const onEnter = useCallback(
    (e) => {
      if (text.trim()) {
        toggleFocus(false);
        navigateTo({ search: { text } });
      }
      e.preventDefault();
    },
    [navigateTo, text, toggleFocus],
  );

  const handleSearch = useDebounce((value: string) => {
    dispatch(searchByType(value.trim()));
  }, DEBOUNCE_TIMER);

  const onChange = useCallback(
    (e) => {
      setText(e.target.value);
      handleSearch(e.target.value);
    },
    [handleSearch],
  );

  const handleMenuBlur = useCallback(
    (e) => {
      e.stopPropagation();
      if (!e.currentTarget.contains(e.relatedTarget)) {
        toggleFocus(false);
      }
    },
    [toggleFocus],
  );

  const clearInput = useCallback(
    (e?: React.SyntheticEvent) => {
      toggleFocus(false);
      setText('');
      handleSearch('');
      if (e) {
        input.current?.focus();
      }
    },
    [handleSearch, toggleFocus],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onEnter(event);
        // eslint-disable-next-line no-magic-numbers
      } else if (event.keyCode === 27) clearInput();
    },
    [clearInput, onEnter],
  );

  const handleMenuFocus = useCallback(
    (e) => {
      e.stopPropagation();
      if (!e.currentTarget.contains(e.relatedTarget) || e.currentTarget === e.relatedTarget) {
        toggleFocus(true);
      }
    },
    [toggleFocus],
  );
  const onLinkClickGen = useCallback(
    ({ pathname, search }: { pathname?: string; search?: { text: string; categoryId?: number } }) =>
      (e) => {
        e.preventDefault();
        clearInput();
        navigateTo({ pathname, search });
        return false;
      },
    [clearInput, navigateTo],
  );
  const onLinkClick = () => {
    clearInput();
    return false;
  };

  const onImageError = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = ImageMageo;
  }, []);

  useLayoutEffect(() => {
    if (!inputContainer.current) return;
    const inputCoords = inputContainer.current.getBoundingClientRect();
    coords.current = {
      left: inputCoords.left,
      top: window.scrollY + inputCoords.top,
      width: inputCoords.width,
    };
  }, [screenWidth]);

  useEffect(() => {
    manageMainScroll(!withResults);
    // eslint-disable-next-line no-magic-numbers
    if (screenWidth > 960 && withResults && inputContainer.current) {
      window.scrollTo({ top: 0 });
    }
  }, [screenWidth, withResults]);

  useEffect(() => {
    if (!new URLSearchParams(location.search).has('s')) clearInput();
  }, [location.search]);

  return (
    <>
      {withResults && <Overlay />}
      <Container
        onFocus={handleMenuFocus}
        onBlur={handleMenuBlur}
        tabIndex={0}
        className={containerClasses}
        onKeyDown={onKeyDown}
        $coords={coords.current}
      >
        <InputContainer ref={inputContainer}>
          <LeftWrapper>
            {withResults && <BackButton onClick={toggleFocus.bind(this, false)} />}
          </LeftWrapper>
          <InputSearchStyled
            tabIndex={1}
            name='search'
            id='search'
            type={'text'}
            placeholder='Что мы сегодня ищем?'
            value={text}
            onChange={onChange}
            ref={input}
            inputMode='search'
          />
          <ButtonsWrap>
            {withResults && <CloseButton onClick={clearInput} />}
            <SearchButton onClick={text && isFocused ? onEnter : undefined} htmlFor='search' />
          </ButtonsWrap>
        </InputContainer>
        {withResults && (
          <Results>
            {Array.isArray(searchingResult.categories) && !!searchingResult.categories.length && (
              <ResultsRow>
                <p className='resultTitle'>Категории</p>
                <ul>
                  {searchingResult.categories.map((item, idx) => (
                    <li key={idx} className='category'>
                      <Link
                        name={item.title}
                        // href={`/s/?s=${encodeSearch(text.trim())}&category_id=${item.id}`}
                        // href={`/category-${item.link.replace('/', '')}`}
                        href={`/category-${item.slug}`}
                        onClick={onLinkClick}
                      />
                    </li>
                  ))}
                </ul>
              </ResultsRow>
            )}
            {Array.isArray(searchingResult.items) && !!searchingResult.items.length && (
              <ResultsRow>
                {searchingResult.items
                  .sort((a, b) =>
                    !a.price || isNaN(parseInt(a.price))
                      ? 1
                      : !b.price || isNaN(parseInt(b.price))
                      ? -1
                      : parseInt(b.price) - parseInt(a.price),
                  )
                  .map((item, idx) => (
                    <li key={idx} className='item'>
                      <figure>
                        <img
                          src={item.picture || ImageMageo}
                          alt={item.name}
                          onError={onImageError}
                        />
                      </figure>
                      <Link
                        name={item.name}
                        href={item.slug ? `/item-${item.slug}` : `/s/?s=${encodeSearch(item.name)}`}
                        onClick={
                          item.slug
                            ? onLinkClickGen({ pathname: `/item-${item.slug}` })
                            : onLinkClickGen({ search: { text: item.name } })
                        }
                      />
                      {item.price && !isNaN(parseInt(item.price)) ? (
                        <p className='price'>{numberStringToReadingString(`${item.price}`)}₽</p>
                      ) : (
                        <p className='price'>Нет в продаже</p>
                      )}
                    </li>
                  ))}
              </ResultsRow>
            )}
            <button className='showAllButton' onClick={onEnter}>
              Смотреть всё
            </button>
          </Results>
        )}
      </Container>
    </>
  );
};
