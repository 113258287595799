import React, { useEffect } from 'react';
import Compare from 'assets/images/btnIcons/compare16.svg';
import { useDispatch, useSelector } from 'react-redux';

import { getCompare } from '../../reducers/compare';
import { selectCompareCount } from '../../reducers/compare/selectors';
import { selectIdentity } from '../../reducers/identity/selectors';
import { Link } from '../Link';

import { Count } from './styles';

interface Props extends React.ComponentPropsWithoutRef<'a'> {}

export const CompareButton = ({}: Props) => {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const count = useSelector(selectCompareCount);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !count && identity.isAuthorised && dispatch(getCompare());
  }, [dispatch, identity.isAuthorised, count]);

  return (
    <Link title='сравнить' className='headerIcon' href='/compare'>
      <Compare />
      {!!count && <Count>{count}</Count>}
    </Link>
  );
};
