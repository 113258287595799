import { H1, H2, H4, H5 } from './styles';
import { TextType } from './types';

type Props = TextType;

export const Title = ({ children }: Props) => {
  return { children };
};

Title.H1 = H1;
Title.H2 = H2;
Title.H4 = H4;
Title.H5 = H5;
