import React, { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { MetaHelmet } from '../MetaHelmet';
export const AppHelmet = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  // @ts-ignore
  const metaData = window.appHelmetData ?? {};
  const searchParamsStr = useMemo(() => {
    const Result = new URLSearchParams();
    const s = searchParams.get('s');
    if (location.pathname === '/s/' && s) {
      Result.set('s', s);
    }
    return Result.toString() ? `?${Result.toString()}` : '';
  }, [location.pathname, searchParams]);
  return (
    <MetaHelmet {...metaData}>
      <meta
        property='og:url'
        content={`${window.location.origin}${location.pathname}${searchParamsStr}`}
      />
    </MetaHelmet>
  );
};
