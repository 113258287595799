import { ContactUsBody, ContactUsDataType, ContactUsErrorsType } from '../../api/contactUs';

export const actionTypes = {
  POST_CONTACT_US: 'POST_CONTACT_US',
};

export type ContactUsState = {
  isRequested: boolean;
  requestBody?: ContactUsBody;
  responseBody?: ContactUsDataType;
  errors?: ContactUsErrorsType;
  success?: boolean;
};
