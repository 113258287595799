import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = {
  items: null,
};

export const search = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_SEARCH}`: {
      return { ...state, corrected_phrase: undefined };
    }
    case `${actionTypes.GET_SEARCH}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const getSearchResult = ({
  searchQuery,
  query,
  categoryId,
}: {
  searchQuery: string;
  query?: string;
  categoryId?: string | null;
}) => ({
  type: `${actionTypes.GET_SEARCH}`,
  payload: { searchQuery, query, categoryId },
});
