import { CompareBody, CompareType } from '../../api/compare';

export const actionTypes = {
  GET_COMPARE: 'GET_COMPARE',
  POST_COMPARE: 'POST_COMPARE',
  DELETE_COMPARE: 'DELETE_COMPARE',
};

export type CompareState = {
  isRequested: boolean;
  requestBody?: CompareBody;
  data?: CompareType;
  count?: number;
};
