import { useLayoutEffect, useState } from 'react';
export const useResize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const getSize = () => setSize([window.innerWidth, window.innerHeight]);
    getSize();
    window.addEventListener('resize', getSize);
    return () => window.removeEventListener('resize', getSize);
  }, []);
  return size;
};
