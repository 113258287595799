import { AnyAction } from 'redux';

import { ResetPasswordBody } from '../../api/resetPassword';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, ResetPasswordState } from './types';

const initialState: ResetPasswordState = {
  isRequested: false,
};

export const resetPassword = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.POST_RESET_PASSWORD}`: {
      return {
        isRequested: true,
        requestBody: action.payload,
        responseBody: undefined,
        errors: undefined,
      };
    }
    case `${actionTypes.POST_RESET_PASSWORD}${SUCCESS}`: {
      return {
        ...state,
        isRequested: false,
        responseBody: Object.assign({}, { done: true }, action.payload),
        errors: undefined,
      };
    }
    case `${actionTypes.POST_RESET_PASSWORD}${ERROR}`: {
      return { ...state, isRequested: false, responseBody: undefined, errors: action.payload };
    }
    default:
      return state;
  }
};

export const postResetPassword = (body: ResetPasswordBody) => ({
  type: `${actionTypes.POST_RESET_PASSWORD}`,
  payload: body,
});
