import { useState } from 'react';
import ArrowTooltip from 'assets/images/arrows/ArrowTooltip.svg';

import { TooltipArrow, TooltipBody, TooltipContent, TooltipTitle, TooltipWrapp } from './styles';
import { TooltipType } from './types';

type Props = TooltipType;
export const Tooltip = ({ title, children, content }: Props) => {
  const [active, setActive] = useState(false);
  let timeout;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 0);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapp onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && content && (
        <TooltipBody>
          {title && <TooltipTitle>{title}</TooltipTitle>}
          <TooltipContent>{content}</TooltipContent>
          <TooltipArrow>
            <ArrowTooltip />
          </TooltipArrow>
        </TooltipBody>
      )}
    </TooltipWrapp>
  );
};
