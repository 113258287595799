import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from 'react-redux';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './reducers/rootSaga';
import { rootReducer, RootState } from './reducers';
// eslint-disable-next-line

export function useDispatch() {
  const dispatch = _useDispatch();
  return (event) => {
    dispatch(event);
  };
}

export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;

const sagaMiddleware = createSagaMiddleware();
const enhancer = composeWithDevTools({});
export const store = createStore(rootReducer, enhancer(applyMiddleware(sagaMiddleware)));
export type Store = typeof store;

sagaMiddleware.run(rootSaga);
