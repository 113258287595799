import { ProductItem } from '../../api/productItem';

export const actionTypes = {
  GET_PRODUCT_ITEM: 'GET_PRODUCT_ITEM',
};

export type Request = {
  /** Date Timestamp of cached data */
  lastDataUpdated: number | null;
  awaitingResponse: boolean;
  lastRequestSent: number | null;
  lastRequestStatus: number | null;
};

export type ProductItemState = {
  item: ProductItem | null;
  request: Request;
};
