import { LoaderStyled, Wrapper } from './styles';

type Props = {
  /**
   * size of Loader
   */
  size?: 'small' | 'normal' | 'large';
  variant?: 'relative' | 'fixed';
  transparent?: boolean;
  children?: any;
};
export const Loader = ({ size = 'normal', variant, transparent }: Props) => {
  return (
    <Wrapper $variant={variant} $transparent={transparent}>
      <LoaderStyled sizeLoader={size} $variant={variant} />
    </Wrapper>
  );
};
