import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = { menu: null };

export const menu = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_MENU}${SUCCESS}`: {
      return { ...state, menu: action.payload };
    }
    default:
      return state;
  }
};

export const getMenu = () => ({
  type: `${actionTypes.GET_MENU}`,
});
