import { call, put, takeEvery } from 'redux-saga/effects';

import { postContactUs } from '../../api/contactUs';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* postContactUsSaga(action) {
  try {
    const data = yield call(postContactUs, action.payload);
    yield put({ type: actionTypes.POST_CONTACT_US + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_CONTACT_US + ERROR, payload: error });
  }
}

export function* watchContactUsSaga() {
  yield takeEvery(actionTypes.POST_CONTACT_US, postContactUsSaga);
}
