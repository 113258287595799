import { call, put, takeEvery } from 'redux-saga/effects';

import { postForgotPassword } from '../../api/forgotPassword';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* postForgotPasswordSaga(action) {
  try {
    const data = yield call(postForgotPassword, action.payload);
    yield put({ type: actionTypes.POST_FORGOT_PASSWORD + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_FORGOT_PASSWORD + ERROR, payload: error });
  }
}

export function* watchForgotPasswordSaga() {
  yield takeEvery(actionTypes.POST_FORGOT_PASSWORD, postForgotPasswordSaga);
}
