import { useState } from 'react';

import MockIMG from '../../assets/mock.jpg';
import { Link } from '../../components/Link';

import { Wrapper } from './styles';
import { SectionCategoryType } from './types';

import './styles.css';

type Props = SectionCategoryType;

const LAST_NUMBER_MAX_INDEX_FOR_SHOW_LIST_CATEGORY = 6;

export const SectionCategory = ({ href, listItem, titleSection, pictureSection }: Props) => {
  const [isOpenList, setIsOpenList] = useState(true);

  const handleClick = () => {
    setIsOpenList(!isOpenList);
  };

  return (
    <Wrapper itemProp='item'>
      <Link href={href}>
        <figure>
          <img itemProp='image' alt='' src={pictureSection ? pictureSection : MockIMG} />
        </figure>
        <div className='h2'>{titleSection}</div>
      </Link>

      <ul>
        {listItem &&
          listItem
            ?.slice(0, LAST_NUMBER_MAX_INDEX_FOR_SHOW_LIST_CATEGORY)
            ?.map(({ id, title, slug }, idx) => {
              return (
                <li itemProp='name' key={id + `${idx}`}>
                  <Link href={`/category-${slug}`} name={title} />
                </li>
              );
            })}
        {isOpenList &&
          listItem &&
          listItem?.length > LAST_NUMBER_MAX_INDEX_FOR_SHOW_LIST_CATEGORY && (
            <li className='listShowMore' onClick={handleClick}>
              Показать ещё ({listItem?.length - LAST_NUMBER_MAX_INDEX_FOR_SHOW_LIST_CATEGORY})
            </li>
          )}
        {!isOpenList &&
          listItem
            ?.slice(LAST_NUMBER_MAX_INDEX_FOR_SHOW_LIST_CATEGORY)
            ?.map(({ id, title, slug }, idx) => {
              return (
                <li itemProp='name' key={id + `${idx}`}>
                  <Link href={`/category-${slug ? slug : id}`} name={title} />
                </li>
              );
            })}
      </ul>
    </Wrapper>
  );
};
