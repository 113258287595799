import { LoginBody, LoginDataType, LoginErrorType } from '../../api/login';

export const actionTypes = {
  POST_LOGIN: 'POST_LOGIN',
  TOGGLE_LOGIN_MODAL: 'TOGGLE_LOGIN_MODAL',
};

export type LoginState = {
  isRequested: boolean;
  loginModalOpen: boolean;
  requestBody?: LoginBody;
  responseBody?: LoginDataType;
  errors?: LoginErrorType;
};
