import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  position: relative;
  transition: 0.3s ease-in;
  transition-property: color;

  &:hover,
  &:focus {
    color: var(--text-hover-color);
  }
`;

export const Count = styled.div`
  position: absolute;
  top: -10px;
  right: -27px;
  background: #ff2f3c;
  color: var(--color-white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
`;
Count.displayName = 'Counter';
