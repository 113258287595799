import { mageoDelete, mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type ComplaintType = {};

export type ComplaintBody = { type: string; id: number };

export function postComplaint(body: ComplaintBody): Promise<ComplaintType> {
  const options = {};
  options[`${body.type}_id`] = body.id;
  return mageoPost({
    url: QUERY.postComplaint,
    body: options,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteComplaint(body: ComplaintBody): Promise<ComplaintType> {
  return mageoDelete({
    url: `${QUERY.deleteComplaint}/${body.type}/${body.id}`,
  });
}
