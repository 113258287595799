import styled from 'styled-components';

const mapTextToColor = {
  grey: '#6F7F89',
  greyLight: '#6F7F89',
  white: '#fff',
};
type Options = { color?: 'grey' | 'greyLight' | 'white' };

export const Text12 = styled.p<Options>`
  color: ${({ color }) => color && mapTextToColor[color]};
  font-size: 12px;
`;
export const Text14 = styled.p<Options>`
  color: ${({ color }) => color && mapTextToColor[color]};
  line-height: 18px;
`;

export const Text16 = styled.p<Options>`
  color: ${({ color }) => color && mapTextToColor[color]};
  font-size: 16px;
`;

export const Text18 = styled.p<Options>`
  color: ${({ color }) => color && mapTextToColor[color]};
  font-size: 18px;
  line-height: 24px;
`;
