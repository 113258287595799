import { Text12, Text14, Text16, Text18 } from './styles';
import { TextType } from './types';

import './styles.css';

type Props = TextType;

export const Text = ({ children }: Props) => {
  return { children };
};

Text.Text12 = Text12;
Text.Text14 = Text14;
Text.Text16 = Text16;
Text.Text18 = Text18;
