import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Eye from '../../../assets/images/btnIcons/eye.svg';
import { Button } from '../../../components/Button';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { Input } from '../../../components/Input';
import { Loader } from '../../../components/Loader';
import { Modal } from '../../../components/Modal';
import { Text } from '../../../components/Text';
import { postForgotPassword } from '../../../reducers/forgotPassword';
import { selectForgotPasswordData } from '../../../reducers/forgotPassword/selectors';
import { ForgotPasswordState } from '../../../reducers/forgotPassword/types';
import { postLogin, toggleLoginModal } from '../../../reducers/login';
import { selectLoginData } from '../../../reducers/login/selectors';
import { LoginState } from '../../../reducers/login/types';
import { toggleRegisterModal } from '../../../reducers/register';
import { SocialProvidersButtons } from '../Register/SocialProvidersButtons';

import {
  Divider,
  ErrorMsg,
  FooterLink,
  Form,
  HideWrapper,
  Msg,
  PasswordWrapper,
  WrapperBtn,
} from './styles';
import { LoginType } from './types';

import './styles.css';

type Props = LoginType;

type State = {
  formData: {
    email: string;
    password: string;
  };
  formType: 'login' | 'password';
  isPasswordHidden: boolean;
};

export const Login = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errors: loginErrors, isRequested: isLoginRequested }: LoginState =
    useSelector(selectLoginData);
  const {
    errors: forgotPasswordErrors,
    isRequested: isForgotPasswordRequested,
    responseBody: forgotPasswordResponse,
  }: ForgotPasswordState = useSelector(selectForgotPasswordData);
  const isRequested = isForgotPasswordRequested || isLoginRequested;
  const { errors, message } = Object.assign({}, loginErrors, forgotPasswordErrors);
  const [state, setState] = useState<State>({
    formData: {
      email: '',
      password: '',
    },
    formType: 'login',
    isPasswordHidden: true,
  });

  const handleLoginSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(postLogin(state.formData));
    },
    [dispatch, state.formData],
  );
  const handleForgotPasswordSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const email = state.formData.email;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isForgotPasswordRequested && !!email && dispatch(postForgotPassword({ email }));
    },
    [dispatch, isForgotPasswordRequested, state.formData.email],
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      const newState = {
        ...prevState,
      };
      newState.formData[name] = value;
      return newState;
    });
  };

  const setToForgotPassword = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      formType: 'password',
    }));
  };

  const setToLogin = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      formType: 'login',
    }));
  };

  const onRegisterLink = useCallback(() => {
    dispatch(toggleLoginModal(false));
    dispatch(toggleRegisterModal(true));
  }, [dispatch]);

  const descriptionBlock = useMemo(() => {
    return (
      <div className={'description'}>
        <Text.Text16>{t('notRegister')}</Text.Text16>
        <Button className={'registerBtn'} onClick={onRegisterLink} name={t('register')} />
      </div>
    );
  }, [onRegisterLink, t]);

  const footerForgotPassword = useMemo(
    () => <FooterLink onClick={setToForgotPassword}>{t('forgotPassword')}</FooterLink>,
    [t],
  );

  const footerLogin = useMemo(
    () => <FooterLink onClick={setToLogin}>{t('returnToLogin')}</FooterLink>,
    [t],
  );

  const togglePassword = useMemo(
    () => (e) => {
      e.preventDefault();
      setState((prevState) => {
        return { ...prevState, isPasswordHidden: !prevState.isPasswordHidden };
      });
    },
    [],
  );

  return (
    <Modal
      title={state.formType === 'login' ? t('loginHeader') : t('forgotPasswordHeader')}
      isOpen={isOpen}
      description={descriptionBlock}
      onClose={onClose}
      footer={state.formType === 'login' ? footerForgotPassword : footerLogin}
    >
      <Form
        onSubmit={(e) =>
          state.formType === 'login' ? handleLoginSubmit(e) : handleForgotPasswordSubmit(e)
        }
      >
        <HideWrapper className={state.formType === 'password' ? '' : 'hide'}>
          {t('resetPasswordHint')}
        </HideWrapper>
        <Input
          name='email'
          placeholder={'Электронная почта'}
          value={state.formData.email}
          onChange={handleInputChange}
          inputMode='email'
        />
        {errors?.email && <ErrorMsg>{errors?.email}</ErrorMsg>}
        <PasswordWrapper className={state.formType === 'password' ? 'hide' : ''}>
          <Input
            name='password'
            type={state.isPasswordHidden ? 'password' : 'text'}
            placeholder={'Пароль'}
            value={state.formData.password}
            onChange={handleInputChange}
            inputMode='text'
          />
          <ButtonIcon
            type='button'
            className='togglePassword'
            onClick={togglePassword}
            size={'s'}
            icon={<Eye />}
          />
        </PasswordWrapper>
        {errors?.password && <ErrorMsg>{errors?.password}</ErrorMsg>}
        {!!forgotPasswordResponse?.success && state.formType === 'password' && (
          <Msg>{t('forgotPasswordSuccessMsg')}</Msg>
        )}
        <WrapperBtn>
          <Button
            type='submit'
            className='enterBtn'
            name={state.formType === 'login' ? t('enter') : t('resetPassword')}
          ></Button>
        </WrapperBtn>
        {!!message && <ErrorMsg>{message}</ErrorMsg>}
        {isRequested && <Loader size='small' variant='relative' />}
        <HideWrapper className={state.formType === 'password' ? 'hide' : ''}>
          <Divider />
          <SocialProvidersButtons />
        </HideWrapper>
      </Form>
    </Modal>
  );
};
