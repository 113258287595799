import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { ButtonCategoryArrow } from '../../components/ButtonCategoryArrow';
import { Hamburger } from '../../components/Hamburger';
import { InputSearch } from '../../components/InputSearch';
import { Link } from '../../components/Link';
import { UserMenu } from '../../components/UserMenu';
import { btnConfig } from '../../pages/Home/config';
import { navConfig } from '../Header/config';
import { Login } from '../modals/Login';
import { Register } from '../modals/Register';

import {
  CloseBtn,
  Overlay,
  StyledCategoryButton,
  StyledList,
  StyledMenu,
  StyledWrapperCategory,
  StyledWrapperUser,
} from './styles';
import { HeaderMobileType } from './types';

type Props = HeaderMobileType;

export const HeaderMobile = ({
  menu,
  setMenuId,
  menuId,
  handleBtnLoginWindow,
  isOpenLogin,
  isOpenRegister,
  identityAuthorised,
  identityLoadedFromStorage,
}: Props) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenSearchIcon, setIsOpenSearchIcon] = useState<boolean>(false);
  const { t } = useTranslation();
  const menuContainer = useRef(null);

  const handleClick = (id) => {
    if (id === menuId) {
      setMenuId(null);
      return;
    }
    setMenuId(id);
  };

  useEffect(() => {
    if (
      isOpenMenu &&
      menuContainer.current &&
      // @ts-ignore
      typeof menuContainer.current.focus === 'function'
    ) {
      // @ts-ignore
      menuContainer.current.focus();
    }
  }, [isOpenMenu]);

  const setIsOpenMenuWithOverlay = useCallback(
    (state: boolean) => {
      if (isOpenSearchIcon && state) setIsOpenSearchIcon(false);
      setIsOpenMenu(state);
    },
    [isOpenSearchIcon],
  );

  const keyDownEvent = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // eslint-disable-next-line no-magic-numbers
      if (isOpenMenu && e.keyCode === 27) setIsOpenMenuWithOverlay(false);
    },
    [setIsOpenMenuWithOverlay, isOpenMenu],
  );

  const onCloseClick = useCallback(() => {
    setIsOpenMenuWithOverlay(false);
  }, [setIsOpenMenuWithOverlay]);

  const handleMenuBlur = useCallback(
    (e) => {
      if (
        !e.currentTarget.contains(e.relatedTarget) &&
        (!e.relatedTarget ||
          !e.relatedTarget.className ||
          !e.relatedTarget.className.includes('hamburger'))
      ) {
        setIsOpenMenuWithOverlay(false);
      }
    },
    [setIsOpenMenuWithOverlay],
  );

  const onSearchToggleFocus = useCallback((state: boolean) => {
    setIsSearchFocused(state);
  }, []);
  const containerClasses = classNames({ isSearchFocused });

  return (
    <>
      <StyledList className={containerClasses}>
        <li className='logo'>
          {navConfig.map(({ name, image, className, href }, idx) => {
            return (
              <div key={`${name}-${idx}`} className={className ? className : ''}>
                <Link name={name} image={image} href={href} />
              </div>
            );
          })}
        </li>

        <li>
          <InputSearch onSearchToggleFocus={onSearchToggleFocus} />
        </li>
        <li>
          <Hamburger open={isOpenMenu} setOpen={setIsOpenMenuWithOverlay} />
        </li>
      </StyledList>

      {!!isOpenMenu && (
        <>
          <Overlay />
          <StyledMenu
            tabIndex={0}
            ref={menuContainer}
            onKeyDown={keyDownEvent}
            onBlur={handleMenuBlur}
          >
            <CloseBtn onClick={onCloseClick} tabIndex={0} />
            <StyledWrapperUser>
              {!identityAuthorised ? (
                <Button
                  className='button headerButton'
                  name='Войти'
                  onClick={handleBtnLoginWindow}
                />
              ) : (
                <UserMenu type='line' isOpenProfile={true} />
              )}
            </StyledWrapperUser>
            {menu?.map((item, idx) => {
              const icon = btnConfig.find(({ id }) => item?.slug === id)?.data?.icon;
              return (
                <StyledCategoryButton key={item?.id + `${idx}`}>
                  <ButtonCategoryArrow
                    id={item.id + `${idx}`}
                    name={t(`${item?.title}`)}
                    icon={icon}
                    onClick={() => handleClick(item.id)}
                    className={menuId === item.id ? 'active forMobile' : 'forMobile'}
                    isOpenArrow={item.id === menuId}
                  />
                  {item.id === menuId && (
                    <StyledWrapperCategory>
                      {!!item.children &&
                        item.children?.map(({ parent_id, children }) => {
                          return (
                            parent_id === item.id &&
                            Boolean(children?.length) &&
                            children?.map(({ title, slug, id }, index) => {
                              return (
                                <li itemProp='name' key={id + `${index}`}>
                                  <Link href={`/category-${slug ? slug : id}`} name={title} />
                                </li>
                              );
                            })
                          );
                        })}
                    </StyledWrapperCategory>
                  )}
                </StyledCategoryButton>
              );
            })}
          </StyledMenu>
        </>
      )}

      {!identityAuthorised && identityLoadedFromStorage && (isOpenLogin || isOpenRegister) && (
        <>
          <Login isOpen={isOpenLogin} onClose={handleBtnLoginWindow} />
          <Register isOpen={isOpenRegister} />
        </>
      )}
    </>
  );
};
