import { call, put, takeLatest } from 'redux-saga/effects';

import { getAllMenuItems } from '../../api/menu';
import { ERROR, SUCCESS } from '../index';
import { hideLoader, showLoader } from '../loader';

import { actionTypes } from './types';

export function* getMenuSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getAllMenuItems);
    yield put({ type: actionTypes.GET_MENU + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_MENU + ERROR, payload: error });
  } finally {
    yield put(hideLoader());
  }
}

export function* watchGetMenuSaga() {
  yield takeLatest(actionTypes.GET_MENU, getMenuSaga);
}
