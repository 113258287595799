import { StyledHamburger } from './styles';
import { HamburgerType } from './types';

import './styles.css';

type Props = HamburgerType;

export const Hamburger = ({ open, setOpen }: Props) => {
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <StyledHamburger className='hamburger' open={open} onClick={handleClick}>
      <div />
      <div />
      <div />
    </StyledHamburger>
  );
};
