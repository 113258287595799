import getByPath from 'utils/path';
import removeByPath from 'utils/remove-by-path';
import setByPath from 'utils/set-by-path';
import { singleton } from 'utils/singleton';

const DEFAULT_STORAGE_KEY = 'SHOPTOOLS';

// export type StorageOptions = {
//   host: string;
// };

type ItemOptions = {
  path?: string[];
  value?: any;
  callback?: (data: any) => void;
  storageType?: 'local' | 'session';
  transformProvider?: () => object;
};

interface UniversalStorage {
  // shared(options: StorageOptions): UniversalStorage; // todo Kudrik add static method to retrieve options suggester
  setItem(item: ItemOptions): boolean;
  getItem(item: ItemOptions): any | undefined;
  removeItem(item: ItemOptions): boolean;
  clear(item: ItemOptions): boolean;
  patch(item: ItemOptions): boolean;
}

export default class Storage implements UniversalStorage {
  static shared = singleton((options) => new Storage(options));
  private storageKey: string;

  constructor({ storageKey = DEFAULT_STORAGE_KEY } = {}) {
    this.storageKey = storageKey;
  }

  _prepareOptions(options: ItemOptions = {}) {
    const result = options;

    result.storageType = options.storageType ? options.storageType : 'local';

    return result;
  }

  _commit(key, value, storageType = 'local') {
    window[`${storageType}Storage`].setItem(key, JSON.stringify(value));
  }

  _get(key, storageType = 'local') {
    return JSON.parse(window[`${storageType}Storage`].getItem(key));
  }

  getItem(options: ItemOptions) {
    const { path, callback, storageType } = this._prepareOptions(options);

    if (!Array.isArray(path)) {
      return undefined;
    }

    const key = path.shift();
    let data = this._get(key, storageType);

    data = path.length ? getByPath(path, data) : data;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    callback && callback(data);

    return data;
  }

  setItem(options: ItemOptions) {
    const { path, value, callback, storageType } = this._prepareOptions(options);

    if (!Array.isArray(path) || path.length === 0 || typeof value === 'undefined') {
      return false;
    }

    const key = path.shift();
    let data = this._get(key, storageType);

    data = path.length ? setByPath(path, data, value) : value;

    this._commit(key, data, storageType);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    callback && callback(true);

    return true;
  }

  removeItem(options: ItemOptions) {
    const { path, callback, storageType } = this._prepareOptions(options);

    if (!Array.isArray(path) || path.length === 0) {
      return false;
    }

    const key = path.shift();
    let data = this._get(key, storageType);

    if (path.length) {
      data = removeByPath(path, data);
      this._commit(key, data, storageType);
    } else {
      window[`${storageType}Storage`].removeItem(key);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    callback && callback(true);

    return true;
  }

  clear(options: ItemOptions) {
    const { callback, storageType } = this._prepareOptions(options);

    window[`${storageType}Storage`].clear();

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    callback && callback(true);

    return true;
  }

  patch(options: ItemOptions) {
    const {
      path,
      value,
      callback,
      storageType,
      transformProvider = Object.assign,
    } = this._prepareOptions(options);

    if (!Array.isArray(path) || path.length === 0 || typeof value === 'undefined') {
      return false;
    }

    if (typeof value !== 'object') {
      return this.setItem(options);
    }

    const key = path.shift();
    let data = this._get(key, storageType);

    const oldValue = path.length ? getByPath(path, data) : data;
    const newValue = transformProvider(typeof oldValue === 'object' ? oldValue : {}, value);

    data = path.length ? setByPath(path, data, newValue) : newValue;

    this._commit(key, data, storageType);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    callback && callback(true);

    return true;
  }
}
