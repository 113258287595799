export const QUERY = {
  getHomaPage: '/v1/home-page-data',
  getMenu: '/v1/menu',
  getArticles: '/v1/articles',
  getArticle: '/v1/article/',
  getRubrics: '/v1/rubrics',
  getCategory: '/v1/category/',
  postLogin: '/v1/login',
  postRegister: '/v1/register',
  postResetPassword: '/v1/password/reset',
  postForgotPassword: '/v1/password/forgot',
  getItem: '/v1/item/',
  getPopularQueries: '/v1/s/populars',
  postFavorite: '/v1/favorite',
  deleteFavorite: '/v1/favorite',
  getFavorite: '/v1/favorites',
  searchByType: '/v1/s/search-as-you-type/',
  categoriesBySearch: '/v1/categories-by-search',
  search: '/v1/s',
  subscription: '/v1/email-subscription',
  postCompare: '/v1/compare',
  getCompare: '/v1/compare',
  deleteCompare: '/v1/compare',
  postComplaint: '/v1/item-complaint',
  deleteComplaint: '/v1/item-complaint',
  postPriceTracking: '/v1/price-tracker',
  deletePriceTracking: '/v1/price-tracker',
  getSocialProviders: '/v1/socials/providers',
  postContactUs: '/v1/contact-form',
  subscribe: '/v1/email-subscription',
  emailConfirm: '/v1/email-confirm',
  sendEmailConfirm: '/v1/send-email-confirm',
  getRecentlyViewedProducts: '/v1/recently-viewed-products',
  getSimilarProducts: '/v1/item/{{slug}}/similar',
};
