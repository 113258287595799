import { RegisterBody, RegisterDataType, RegisterErrorsType } from '../../api/register';

export const actionTypes = {
  POST_REGISTER: 'POST_REGISTER',
  TOGGLE_REGISTER_MODAL: 'TOGGLE_REGISTER_MODAL',
};

export type RegisterState = {
  isRequested: boolean;
  registerModalOpen: boolean;
  requestBody?: RegisterBody;
  responseBody?: RegisterDataType;
  errors?: RegisterErrorsType;
};
