export const theme = {
  colors: {
    primary: '#589abe',
    secondary: '#f4f5f9',
    third: '#6f7f89',
    behavioral: '#0D6797',
    dark: '#3b4449',
    dark18: '#181818',
    dark1c: '#1c274c',
    chocolate: '#4F4F4F',
    greyF5: '#F5F5F5',
    greyEE: '#EEEEF8',
    greyD6: '#D6D6DF',
    white: '#ffffff',
    error: '#ff2d38',
  },
  fontFamily: {
    Roboto: 'Roboto sans-serif',
    Intel: 'Intel, sans-serif',
  },
  weight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  transition: {
    primary: '.3s ease',
  },
  size: {
    size1: 4,
    size2: 8,
    size3: 12,
    size4: 16,
    size5: 20,
    size6: 24,
    size7: 28,
    size8: 32,
    size9: 36,
    size10: 40,
  },
  containers: {
    xs: 360,
    sm: 480,
    md: 768,
    lg: 960,
    xl: 1200,
    xls: 1400,
    xxl: 1600,
  },
};
