import { mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type RegisterDataType = {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  accessToken: string;
  refreshToken: string | null;
};

export type RegisterMetaType = {
  include: [];
  custom: [];
};

export type RegisterErrorsType = {
  [key in keyof RegisterBody]: string[];
};

export type RegisterType = {
  data: RegisterDataType;
  meta: RegisterMetaType;
};

export type RegisterBody = { email: string; password: string; name?: string };

export function postRegister(body: RegisterBody): Promise<RegisterDataType> {
  return mageoPost({
    url: QUERY.postRegister,
    body,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  })
    .catch<RegisterErrorsType>(({ response }) => {
      return Promise.reject(response?.data?.errors);
    })
    .then((response: RegisterType) => response.data);
}
