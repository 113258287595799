import Appliances from '../../assets/images/btnIcons/appliances.svg';
import Audio from '../../assets/images/btnIcons/audio.svg';
import Auto from '../../assets/images/btnIcons/auto.svg';
import Camera from '../../assets/images/btnIcons/camera.svg';
import Climate from '../../assets/images/btnIcons/climate.svg';
import Clock from '../../assets/images/btnIcons/clock.svg';
import Comp from '../../assets/images/btnIcons/comp.svg';
import Home from '../../assets/images/btnIcons/home.svg';
import Instrument from '../../assets/images/btnIcons/instrument.svg';
import Mobil from '../../assets/images/btnIcons/mobil.svg';
import Sport from '../../assets/images/btnIcons/sport.svg';
import Tourism from '../../assets/images/btnIcons/tourism.svg';
import Toys from '../../assets/images/btnIcons/toys.svg';
import Tv from '../../assets/images/btnIcons/tv.svg';

export const btnConfig = [
  {
    id: 'mobilnye-i-sviaz',
    data: {
      icon: <Mobil />,
      label: 'menu.mobile',
      key: 'mobile',
    },
  },
  {
    id: 'fototehnika',
    data: {
      icon: <Camera />,
      label: 'menu.camera',
      key: 'camera',
    },
  },
  {
    id: 'tv-i-videotexnika',
    data: {
      icon: <Tv />,
      label: 'menu.tv',
      key: 'tv',
    },
  },
  {
    id: 'audiotexnika',
    data: {
      icon: <Audio />,
      label: 'menu.audio',
      key: 'audio',
    },
  },
  {
    id: 'krupnaya-bytovaya-texnika',
    data: {
      icon: <Appliances />,
      label: 'menu.appliances',
      key: 'appliances',
    },
  },
  {
    id: 'dom-i-remont',
    data: {
      icon: <Home />,
      label: 'menu.home',
      key: 'home',
    },
  },
  {
    id: 'avto-i-moto',
    data: {
      icon: <Auto />,
      label: 'menu.auto',
      key: 'auto',
    },
  },
  {
    id: 'instrument-i-sadovaya-texnika',
    data: {
      icon: <Instrument />,
      label: 'menu.instrument',
      key: 'instrument',
    },
  },
  {
    id: 'chasy-ukrasheniya-i-aksessuary',
    data: {
      icon: <Clock />,
      label: 'menu.clock',
      key: 'clock',
    },
  },
  {
    id: 'kompyuternaya-texnika',
    data: {
      icon: <Comp />,
      label: 'menu.comp',
      key: 'comp',
    },
  },

  {
    id: 'detskie-tovary-i-igrushki',
    data: {
      icon: <Toys />,
      label: 'menu.toys',
      key: 'toys',
    },
  },
  {
    id: 'turizm-i-rybalka',
    data: {
      icon: <Tourism />,
      label: 'menu.tourism',
      key: 'tourism',
    },
  },
  {
    id: 'sport-i-aktivnyj-otdyx',
    data: {
      icon: <Sport />,
      label: 'menu.sport',
      key: 'sport',
    },
  },
  {
    id: 'klimat-otoplenie-i-vodosnabzhenie',
    data: {
      icon: <Climate />,
      label: 'menu.climate',
      key: 'climate',
    },
  },
];
