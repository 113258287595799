import { mageoGet } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type CardType = {
  rating: string;
  picture: string;
  is_favorite: boolean;
  is_compare: boolean;
  offers_count: number;
  price: PriceType;
  name: string;
  id: number;
  shops: ShopType[];
  url: string;
  type_of_item: string;
  labels?: Array<'popular' | 'top' | 'hit' | 'recommend' | 'discount'>;
};

export type ShopType = {
  price: string;
  name: string;
  url: string;
  logo: string;
};

export type PriceType = {
  value: string;
  from: string;
  currency: boolean;
};

export type PopularQueryType = {
  search: string;
  count: number;
};

export type PopularQueriesType = {
  items: PopularQueryType[];
};
// TODO: REMOVE ALL OLD HOME PAGE PRODUCTS FUNCTIONALLITY
export function getHomePageProducts(): Promise<CardType[]> {
  return mageoGet(QUERY.getHomaPage);
}
export function getPopularQueries(): Promise<PopularQueriesType> {
  return mageoGet(QUERY.getPopularQueries);
}
