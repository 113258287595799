import { mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type ContactUsDataType = {};

export type ContactUsErrorsType = {
  [key in keyof ContactUsBody]: string[];
};

export type ContactUsType = {
  data: ContactUsDataType;
};

export type ContactUsBody = { email: string; information: string; name: string };

export function postContactUs(body: ContactUsBody): Promise<ContactUsDataType> {
  return mageoPost({
    url: QUERY.postContactUs,
    body,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  })
    .catch<ContactUsErrorsType>(({ response }) => {
      return Promise.reject(response?.data?.errors);
    })
    .then((response: ContactUsType) => response.data);
}
