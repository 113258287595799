import { getArticle, getArticles, getRubrics } from 'api/articles';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getArticlesSaga(action) {
  try {
    const data = yield call(getArticles, action.queryOptions);
    yield put({ type: actionTypes.GET_ARTICLES + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_ARTICLES + ERROR, payload: error });
  }
}

export function* watchGetArticlesSaga() {
  yield takeLatest(actionTypes.GET_ARTICLES, getArticlesSaga);
}

export function* getArticleSaga(action) {
  try {
    const data = yield call(getArticle, action.slug);
    yield put({ type: actionTypes.GET_ARTICLE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_ARTICLE + ERROR, payload: error });
  }
}

export function* watchGetArticleSaga() {
  yield takeLatest(actionTypes.GET_ARTICLE, getArticleSaga);
}

export function* getRubricsSaga() {
  try {
    const data = yield call(getRubrics);
    yield put({ type: actionTypes.GET_RUBRICS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_RUBRICS + ERROR, payload: error });
  }
}

export function* watchGetRubricsSaga() {
  yield takeLatest(actionTypes.GET_RUBRICS, getRubricsSaga);
}
