import { AnyAction } from 'redux';

import { PriceTrackingBody } from '../../api/pricetracking';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, PriceTrackingState } from './types';

const initialState: PriceTrackingState = {
  isRequested: false,
};

export const pricetracking = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.DELETE_PRICETRACKING}`:
    case `${actionTypes.POST_PRICETRACKING}`: {
      return { ...state, isRequested: true, requestBody: action.payload };
    }
    case `${actionTypes.DELETE_PRICETRACKING}${SUCCESS}`:
    case `${actionTypes.POST_PRICETRACKING}${SUCCESS}`: {
      return { ...state, isRequested: false };
    }
    case `${actionTypes.DELETE_PRICETRACKING}${ERROR}`:
    case `${actionTypes.POST_PRICETRACKING}${ERROR}`: {
      return { ...state, isRequested: false };
    }
    default:
      return state;
  }
};

export const postPriceTracking = (body: PriceTrackingBody) => ({
  type: `${actionTypes.POST_PRICETRACKING}`,
  payload: body,
});

export const deletePriceTracking = (body: PriceTrackingBody) => ({
  type: `${actionTypes.DELETE_PRICETRACKING}`,
  payload: body,
});
