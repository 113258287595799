import { FooterType } from './types';

export const footerConfig: FooterType[] = [
  {
    title: 'O Компании',
    listOfSupportingLinks: [
      {
        text: 'О нас',
        href: '/article-o-nas',
      },
      { text: 'Контакты', href: '/article-contact' },
      { text: 'Блог', href: '/articles/' },
    ],
  },
  {
    title: 'Юридическая информация',
    listOfSupportingLinks: [
      { text: 'Пользовательское соглашение', href: '/article-polzovatelskoe-soglashenie' },
      { text: 'Политика конфиденциальности', href: '/article-politika-konfidencialnosti' },
    ],
  },
  // {
  //   title: 'Доставка',
  //   listOfSupportingLinks: [
  //     { text: 'Условия доставки', href: '' },
  //     { text: 'Гарантии', href: '' },
  //     { text: 'Способы оплаты', href: '' },
  //     { text: 'Условия возврата', href: '' },
  //   ],
  // },
  {
    title: 'Разное',
    listOfSupportingLinks: [
      { text: 'Сотрудничество', href: '/article-sotrudnichestvo' },
      { text: 'Реклама', href: '/article-reklama' },
    ],
  },
];
