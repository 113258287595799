import { call, put, takeLatest } from 'redux-saga/effects';

import { searchByTyping } from '../../api/search';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* searchSaga(action) {
  try {
    if (!action.payload) {
      yield put({
        type: actionTypes.SEARCH_BY_TYPE + SUCCESS,
        payload: { result: undefined, searchText: action.payload },
      });
    } else {
      const data = yield call(searchByTyping, action.payload);
      yield put({
        type: actionTypes.SEARCH_BY_TYPE + SUCCESS,
        payload: { result: data, searchText: action.payload },
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.SEARCH_BY_TYPE + ERROR, payload: error });
  }
}

export function* watchSearchByTypeSaga() {
  yield takeLatest(actionTypes.SEARCH_BY_TYPE, searchSaga);
}
