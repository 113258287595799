import styled from 'styled-components';

export const WrapperBtn = styled.div`
  //margin-top: 25px;
  //margin-bottom: 10px;
  margin: 0 auto;
  width: 200px;
`;
export const StyledCookies = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px 8%;

  p {
    margin: 10px;
  }
`;
