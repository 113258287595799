import { MarketingScripts } from './MarketingScripts/MarketingScripts';
import { SetIdentityOnWindowLoaded } from './SetIdentityOnWindowLoaded';

export const Services = () => {
  return (
    <>
      <SetIdentityOnWindowLoaded />
      <MarketingScripts />
    </>
  );
};
