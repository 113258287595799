import { ComplaintBody } from '../../api/complaint';

export const actionTypes = {
  POST_COMPLAINT: 'POST_COMPLAINT',
  DELETE_COMPLAINT: 'DELETE_COMPLAINT',
};

export type ComplaintState = {
  isRequested: boolean;
  requestBody?: ComplaintBody;
};
