import { actionTypes } from './types';

const initialState = {
  loading: false,
  loadersCount: 0,
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${actionTypes.SHOW_LOADER}`:
      return {
        ...state,
        loading: true,
        loadersCount: state.loadersCount + 1,
      };
    case `${actionTypes.HIDE_LOADER}`:
      return {
        ...state,
        loading: state.loadersCount - 1 !== 0,
        loadersCount: state.loadersCount - 1,
      };
    default:
      return state;
  }
};

export const showLoader = () => ({
  type: actionTypes.SHOW_LOADER,
});
export const hideLoader = () => ({
  type: actionTypes.HIDE_LOADER,
});
