import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useResizeScreen from '../../hooks/useResizeScreen';
import { selectIdentity } from '../../reducers/identity/selectors';
import { toggleLoginModal } from '../../reducers/login';
import { selectLoginData } from '../../reducers/login/selectors';
import { getMenu } from '../../reducers/menu';
import { selectMenu } from '../../reducers/menu/selectors';
import { toggleRegisterModal } from '../../reducers/register';
import { selectRegisterData } from '../../reducers/register/selectors';
import { HeaderContainer } from '../HeaderContainer';
import { HeaderMobile } from '../HeaderMobile';

import { StyledHeader } from './styles';

const DEFAULT_MENU_ID = 8;
const SCREEN_BREAK_POINT = 960;
export const Header = () => {
  const dispatch = useDispatch();
  const menu = useSelector(selectMenu);
  const identity = useSelector(selectIdentity);
  const screenWidth = useResizeScreen();

  const [menuId, setMenuId] = useState(screenWidth < SCREEN_BREAK_POINT ? null : DEFAULT_MENU_ID);
  const { loginModalOpen: isOpenLogin } = useSelector(selectLoginData);
  const { registerModalOpen: isOpenRegister } = useSelector(selectRegisterData);

  useEffect(() => {
    if (!menu) {
      dispatch(getMenu());
    }
  }, [dispatch]);

  const handleBtnLoginWindow = () => {
    dispatch(toggleRegisterModal(false));
    dispatch(toggleLoginModal(!isOpenLogin));
  };

  return (
    <StyledHeader>
      <div className='navigate container'>
        {screenWidth < SCREEN_BREAK_POINT ? (
          <HeaderMobile
            menu={menu}
            menuId={menuId}
            setMenuId={setMenuId}
            isOpenLogin={isOpenLogin}
            isOpenRegister={isOpenRegister}
            handleBtnLoginWindow={handleBtnLoginWindow}
            identityAuthorised={Boolean(identity.isAuthorised)}
            identityLoadedFromStorage={Boolean(identity.isLoadedFromStorage)}
          />
        ) : (
          <HeaderContainer
            menu={menu}
            menuId={menuId ?? DEFAULT_MENU_ID}
            setMenuId={setMenuId}
            isOpenLogin={isOpenLogin}
            isOpenRegister={isOpenRegister}
            handleBtnLoginWindow={handleBtnLoginWindow}
            identityAuthorised={Boolean(identity.isAuthorised)}
            identityLoadedFromStorage={Boolean(identity.isLoadedFromStorage)}
          />
        )}
      </div>
    </StyledHeader>
  );
};
