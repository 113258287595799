import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Cross from '../../assets/images/btnIcons/cross.svg';
import { Accordion } from '../../components/Accordion';
import { Button } from '../../components/Button';
import { ButtonCategoryArrow } from '../../components/ButtonCategoryArrow';
import { ButtonIcon } from '../../components/ButtonIcon';
import { CompareButton } from '../../components/CompareButton';
import { FavoritesButton } from '../../components/FavoritesButton';
import { InputSearch } from '../../components/InputSearch';
import { Link } from '../../components/Link';
import { UserMenu } from '../../components/UserMenu';
import { btnConfig } from '../../pages/Home/config';
import { Login } from '../modals/Login';
import { Register } from '../modals/Register';
import { SectionCategory } from '../SectionCategory';

import { menuConfig, navConfig } from './config';
import { HeaderWrapper, LoggedUserMenu } from './styles';
import { HeaderContainerType } from './types';

import './styles.css';

type Props = HeaderContainerType;

export const HeaderContainer = ({
  menu,
  setMenuId,
  menuId,
  identityAuthorised,
  identityLoadedFromStorage,
  handleBtnLoginWindow,
  isOpenLogin,
  isOpenRegister,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const menuContainer = useRef(null);

  const selfMenu = menu?.find(({ id }) => menuId === id);
  const titleMenuHeader = selfMenu?.title;
  const slugMenuHeader = selfMenu?.slug;
  const idMenuHeader = selfMenu?.id;

  const toggleMenuState = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const setMenuState = useCallback((state: boolean) => {
    setOpenMenu(state);
  }, []);

  useEffect(() => {
    if (
      openMenu &&
      menuContainer.current &&
      // @ts-ignore
      typeof menuContainer.current.focus === 'function'
    ) {
      // @ts-ignore
      menuContainer.current.focus();
    }
  }, [openMenu]);

  const keyDownEvent = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // eslint-disable-next-line no-magic-numbers
      if (openMenu && e.keyCode === 27) toggleMenuState();
    },
    [openMenu, toggleMenuState],
  );
  const handleMenuBlur = useCallback(
    (e) => {
      if (
        !e.currentTarget.contains(e.relatedTarget) &&
        (!e.relatedTarget ||
          !e.relatedTarget.className ||
          !(
            e.relatedTarget.className.includes('buttonDrop') ||
            e.relatedTarget.className.includes('dropdown')
          ))
      ) {
        toggleMenuState();
      }
    },
    [toggleMenuState],
  );

  useEffect(() => {
    setMenuState(false);
  }, [location.key, setMenuState]);

  return (
    <>
      <HeaderWrapper>
        <li>
          {navConfig.map(({ name, image, className, href }, idx) => {
            return (
              <div key={`${name}-${idx}`} className={className ? className : ''}>
                <Link name={name} image={image} href={href} />
              </div>
            );
          })}
        </li>
        <li>
          <ul>
            <li>
              <Accordion
                className='dropdown'
                classNameButton='buttonDrop'
                placeholder={t('categories')}
                onClick={toggleMenuState}
                openDropdown={openMenu}
                tabIndex={0}
              />
            </li>
            <li>
              <ul className='listNav'>
                {menuConfig.map(({ name, href }, idx) => {
                  return (
                    <li key={`${name}-${idx}`}>
                      <Link name={t(`${name}`)} href={href} />
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <InputSearch />
        </li>
        <li>
          {!identityAuthorised ? (
            <Button className='button headerButton' name='Войти' onClick={handleBtnLoginWindow} />
          ) : (
            <LoggedUserMenu>
              <CompareButton />
              <FavoritesButton />
              <UserMenu />
            </LoggedUserMenu>
          )}
        </li>
      </HeaderWrapper>

      {!identityAuthorised && identityLoadedFromStorage && (
        <>
          <Login isOpen={isOpenLogin} onClose={handleBtnLoginWindow} />
          <Register isOpen={isOpenRegister} />
        </>
      )}

      {openMenu && (
        <div
          className='menu'
          tabIndex={0}
          ref={menuContainer}
          onKeyDown={keyDownEvent}
          onBlur={handleMenuBlur}
        >
          <div className='menuButtons' itemScope itemType='http://schema.org/ItemList'>
            {/* когда появятся иконки кнопки на бэке-убрать доп мап кнопок */}
            {menu?.map((item, idx) => {
              const icon = btnConfig.find(({ id }) => item?.slug === id)?.data?.icon;
              return (
                <div
                  key={item?.id + `${idx}`}
                  itemProp='itemListElement'
                  itemScope
                  itemType='http://schema.org/ListItem'
                >
                  <ButtonCategoryArrow
                    id={item.id + `${idx}`}
                    name={t(`${item?.title}`)}
                    icon={icon}
                    onClick={() => setMenuId(item.id)}
                    className={menuId === item.id ? 'active' : ''}
                  />
                  <meta itemProp='position' content={idx + 1} />
                </div>
              );
            })}
          </div>

          <div className='menuRight'>
            {menu && titleMenuHeader && (
              <div className='headerMenu'>
                <h1>
                  <Link
                    href={`/category-${slugMenuHeader ? slugMenuHeader : idMenuHeader}`}
                    name={titleMenuHeader}
                  />
                </h1>
                <ButtonIcon icon={<Cross />} size={'s'} onClick={toggleMenuState} />
              </div>
            )}
            <div
              className='menuCategory'
              itemProp='itemListElement'
              itemScope
              itemType='http://schema.org/ListItem'
            >
              {menu?.map(({ id, children }, idx) => {
                if (menuId === id) {
                  return children?.map((item) => {
                    if (item.parent_id === id) {
                      return (
                        <SectionCategory
                          listItem={item?.children}
                          titleSection={item?.title}
                          pictureSection={item?.picture}
                          href={`/category-${item.slug ? item.slug : item.id}`}
                          key={`${idx}` + item.id}
                        />
                      );
                    }
                    return null;
                  });
                }
                return null;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
