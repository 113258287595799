import { useCallback, useEffect, useState } from 'react';
import ArrowUp from 'assets/images/arrows/ArrowStrokeUp.svg';
import classNames from 'classnames';

import { ScrollToTopButton } from './styles';

const MIN_SCROLL_TO_VISIBLE = 400;

export const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);
  const classes = classNames({ visible });
  const handleScroll = useCallback(() => {
    if (window.scrollY > MIN_SCROLL_TO_VISIBLE) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);
  const handleClick = useCallback((e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <ScrollToTopButton onClick={handleClick} className={classes}>
      <ArrowUp />
    </ScrollToTopButton>
  );
};
