import styled from 'styled-components';

export const StyledHamburger = styled.button<{ open: boolean }>`
  width: 23px;
  height: 16px;
  padding: 0;
  background: transparent;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 1;

  div {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: ${({ open }) => (open ? '#fd683c' : '#3b4449')};
  }
`;
