import { call, put, takeLatest } from 'redux-saga/effects';

import { categoriesBySearch } from '../../api/categoriesBySearch';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getCategoriesBySearchSaga(action) {
  try {
    // yield put(showLoader());
    const data = yield call(categoriesBySearch, action.payload?.searchQuery);
    yield put({ type: actionTypes.GET_CATEGORIES_BY_SEARCH + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_CATEGORIES_BY_SEARCH + ERROR, payload: error });
  } finally {
    // yield put(hideLoader());
  }
}

export function* watchGetCategoriesBySearchSaga() {
  yield takeLatest(actionTypes.GET_CATEGORIES_BY_SEARCH, getCategoriesBySearchSaga);
}
