import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = { items: [] };

export const similarProducts = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_SIMILAR_PRODUCTS}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const getSimilarProducts = (slug: string) => ({
  type: `${actionTypes.GET_SIMILAR_PRODUCTS}`,
  payload: {
    slug,
  },
});
