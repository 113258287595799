import styled from 'styled-components';

export const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.25rem;
  line-height: 40px;
  overflow: hidden;
  user-select: none;
  text-align: center;
  color: #fff;
  background-color: #bdbdbd;

  &.line {
    cursor: auto;
  }
`;
