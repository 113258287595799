import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resendEmailConfirm } from '../../api/emailConfirm';
import { identityLogout, identityUpdate } from '../../reducers/identity';
import { selectIdentity } from '../../reducers/identity/selectors';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { Modal } from '../Modal';

import { ListItemStyled, ListWrapper, Wrapper } from './styles';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  isOpenProfile?: boolean;
  type?: 'line' | 'default';
}

export const UserMenu = ({ className, isOpenProfile = false, type = 'default' }: Props) => {
  const identity = useSelector(selectIdentity);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(isOpenProfile);
  const [emailConfirmOpen, setEmailConfirmOpen] = useState(false);
  const { t } = useTranslation();
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen],
  );
  const handleBlur = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleLogout = useCallback(() => {
    dispatch(identityLogout());
  }, [dispatch]);
  const goToCompare = useCallback(() => {
    navigate('/compare');
  }, [navigate]);
  const goToFavorites = useCallback(() => {
    navigate('/favorites');
  }, [navigate]);
  const toggleEmailConfirm = useCallback(() => {
    setEmailConfirmOpen((prevState) => !prevState);
  }, []);
  const sendEmailConfirmation = useCallback(() => {
    resendEmailConfirm().catch((data) => {
      if (data?.message === 'Адрес электронной почты пользователя уже подтвержден.') {
        dispatch(
          identityUpdate({
            ...identity,
            isNeededEmailConfirmation: false,
          }),
        );
      }
    });
    toggleEmailConfirm();
  }, [dispatch, identity, toggleEmailConfirm]);

  return (
    <Wrapper
      onBlur={type === 'default' ? handleBlur : undefined}
      tabIndex={1}
      className={className}
    >
      <Avatar type={type} onClick={type === 'default' ? handleClick : undefined} />

      {isOpen && (
        <ListWrapper $type={type}>
          {!!identity.isNeededEmailConfirmation && (
            <ListItemStyled onClick={toggleEmailConfirm} className='emailConfirm'>
              <p>Подтвердите E-mail</p>
            </ListItemStyled>
          )}
          <ListItemStyled onClick={goToFavorites} className='favorites'>
            <p>{t('favorites')}</p>
          </ListItemStyled>
          <ListItemStyled onClick={goToCompare} className='compare'>
            <p>{t('compareProducts')}</p>
          </ListItemStyled>
          <ListItemStyled onClick={handleLogout} className='logout'>
            <p>{t('logout')}</p>
          </ListItemStyled>
        </ListWrapper>
      )}
      {!!identity.isNeededEmailConfirmation && !!emailConfirmOpen && (
        <Modal
          title='Подтвердить регистрацию'
          isOpen={emailConfirmOpen}
          onClose={toggleEmailConfirm}
        >
          <>
            <p className='withMargins'>
              Чтобы воспользоваться полным функционалом сайта, вам необходимо подтвердить E-mail.
            </p>
            <p className='withMargins'>
              Нажмите на кнопку ниже, чтобы отправить письмо с ссылкой на подтверждение по
              указанному вами адресу {identity.email}
            </p>
            <Button
              className='enterBtn'
              onClick={sendEmailConfirmation}
              name='Выслать письмо'
            ></Button>
            <p className='withMargins'>
              Как правило, письмо приходит в течении 5-10 минут. Не забудьте проверить папку СПАМ.
            </p>
          </>
        </Modal>
      )}
    </Wrapper>
  );
};
