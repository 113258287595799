import { FavoriteBody, FavoriteType } from '../../api/favorite';

export const actionTypes = {
  GET_FAVORITE: 'GET_FAVORITE',
  POST_FAVORITE: 'POST_FAVORITE',
  DELETE_FAVORITE: 'DELETE_FAVORITE',
};

export type FavoriteState = {
  isRequested: boolean;
  requestBody?: FavoriteBody;
  data?: FavoriteType;
  count?: number;
};
