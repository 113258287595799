import { getHomePageSlidersProducts } from 'api/productsSliders';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ERROR, SUCCESS } from '../index';
import { hideLoader, showLoader } from '../loader';

import { actionTypes } from './types';

export function* getHomePageProductsSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getHomePageSlidersProducts);
    yield put({ type: actionTypes.GET_HOME_PAGE_PRODUCTS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_HOME_PAGE_PRODUCTS + ERROR, payload: error });
  } finally {
    yield put(hideLoader());
  }
}

export function* watchGetHomePageProductsSaga() {
  yield takeLatest(actionTypes.GET_HOME_PAGE_PRODUCTS, getHomePageProductsSaga);
}
