import styled from 'styled-components';

export const ButtonWrapper = styled.a<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 213px;
  height: 176px;
  background: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;

  &,
  *:visited,
  &:hover {
    color: var(--text-color);
  }

  @media screen and (max-width: 960px) {
    height: 100px;
    width: 150px;
  }

  .svg-wrapper {
    height: 80px;
    margin-top: 20px;
    display: flex;
    align-items: end;
    justify-content: center;
    transition: transform 0.3s;
    transform: scale(0.85);
    transform-origin: center 80%;

    @media screen and (max-width: 960px) {
      margin-top: -22px;
      transform: scale(0.5);
    }
  }

  &:hover .svg-wrapper {
    transform: scale(1);
  }

  @media screen and (max-width: 960px) {
    &:hover .svg-wrapper {
      transform: scale(0.65);
    }
  }

  // span {
  //   color: ${({ selected }) => (selected ? '#FFFFFF' : '#4F4F4F')};
  // }
  //
  // div {
  //   g {
  //     fill: ${({ selected }) => (selected ? '#FFFFFF' : '#4F4F4F')};
  //   }
  // }
`;
export const Text = styled.span`
  width: 100%;
  display: block;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 960px) {
    font-size: 13px;
  }
`;

export const ButtonSWrapper = styled.button`
  background: transparent;
`;

interface ButtonLineType {
  $backgroundIcon?: string;
}

export const ButtonLine = styled.button<ButtonLineType>`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  appearance: none;
  padding-left: 1.5em;
  background: ${(props) =>
    props.$backgroundIcon
      ? `transparent url("${props.$backgroundIcon}") left center no-repeat`
      : 'none'};
  background-size: contain;
`;
