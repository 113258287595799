import { mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type ResetPasswordDataType = {};

export type RegisterMetaType = {
  include: [];
  custom: [];
};

export type ResetPasswordErrorsType = {
  [key in keyof ResetPasswordBody]: string[];
};

export type ResetPasswordType = {
  data: ResetPasswordDataType;
  meta: RegisterMetaType;
};

export type ResetPasswordBody = { email: string; password: string; token: string };

export function postResetPassword(body: ResetPasswordBody): Promise<ResetPasswordDataType> {
  return mageoPost({
    url: QUERY.postResetPassword,
    body,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  })
    .catch<ResetPasswordErrorsType>(({ response }) => {
      return Promise.reject(response?.data?.errors);
    })
    .then((response: ResetPasswordType) => response.data);
}
