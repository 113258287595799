import { call, put, takeEvery } from 'redux-saga/effects';

import { deleteComplaint, postComplaint } from '../../api/complaint';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* postComplaintSaga(action) {
  try {
    const data = yield call(postComplaint, action.payload);
    yield put({ type: actionTypes.POST_COMPLAINT + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_COMPLAINT + ERROR, payload: error });
  }
}

export function* deleteComplaintSaga(action) {
  try {
    const data = yield call(deleteComplaint, action.payload);
    yield put({ type: actionTypes.DELETE_COMPLAINT + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_COMPLAINT + ERROR, payload: error });
  }
}
export function* watchComplaintSaga() {
  yield takeEvery(actionTypes.POST_COMPLAINT, postComplaintSaga);
  yield takeEvery(actionTypes.DELETE_COMPLAINT, deleteComplaintSaga);
}
