export const actionTypes = {
  IDENTITY_UPDATE: 'IDENTITY_UPDATE',
};

export type IdentityState = {
  isAuthorised: boolean;
  isLoadedFromStorage?: boolean;
  isAdmin?: boolean;
  email?: string;
  name?: string;
  password?: string;
  avatarLetters?: String;
  authorization?: {
    token: string;
  };
  isNeededEmailConfirmation?: boolean;
  role?: 'user' | 'admin';
  permissions?: { key: boolean };
};
