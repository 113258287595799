import { mageoGet } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';
import { CardType } from '../product';

export type PaginationType = {
  currentPage: number;
  lastPage: number;
  path: string;
  perPage: number;
  total: number;
};

export type BreadcrumbsType = {
  activeClass: string;
  link?: string;
  name: string;
};

type FacetType = {
  category_id: string;
  attribute_id: string;
  attribute_name: string;
  tooltip: string;
  values: FacetValuesType[];
};

export type FacetValuesType = {
  text: string;
  selected?: boolean;
  id_value: string;
  active?: boolean;
};

type PriceType = {
  min: number;
  max: number;
};

type CategoryInfoType = {
  title: string;
  h1: string | null;
  meta: {
    title: string;
    keywords: string;
    description: string;
    tags: string | null;
    noindex: boolean;
  };
};

export type CategoryBrandType = {
  brand_id: number;
  brand_name: string;
  active: boolean;
};

export type CategoryType = {
  breadcrumbs: BreadcrumbsType[];
  facets: FacetType[];
  items: CardType[];
  pagination: PaginationType;
  price: PriceType;
  title: string;
  info: CategoryInfoType;
  brands: CategoryBrandType[];
};

export function getDataCategory(id, queryString?: string): Promise<CategoryType> {
  if (queryString) {
    const url = `${QUERY.getCategory}${id}?${queryString}`;

    return mageoGet(url);
  }
  return mageoGet(QUERY.getCategory + id);
}
