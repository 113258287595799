import './styles.css';
export function initAdmin() {
  const body = document.getElementsByTagName('BODY')[0];
  // eslint-disable-next-line prefer-const
  let timeoutId;

  function clean() {
    const btns = document.getElementsByClassName('btn');
    for (let i = 0; i < btns.length; i++) {
      btns[i].remove();
    }
    const adminPanel = document.getElementsByClassName('admin-panel');
    if (adminPanel.length) {
      adminPanel[0].remove();
    }
    if (timeoutId) clearTimeout(timeoutId);
  }
  clean();
  // eslint-disable-next-line no-magic-numbers
  timeoutId = setTimeout(awaitingLoad, 2000);

  const pageData = getPageData();
  if (pageData.type) {
    let adminPanel = document.getElementsByClassName('admin-panel');

    adminPanel = document.createElement('div');
    adminPanel.setAttribute('class', 'admin-panel');

    if (pageData.ispEdit) {
      const editBtn = document.createElement('a');
      editBtn.setAttribute('class', 'btn yellow edit');
      editBtn.href = pageData.ispEdit;
      editBtn.target = '_blank';
      editBtn.title = 'Редактировать';
      adminPanel.appendChild(editBtn);
    }

    if (pageData.ispMatch) {
      const matchBtn = document.createElement('a');
      matchBtn.setAttribute('class', 'btn blue match');
      matchBtn.href = pageData.ispMatch;
      matchBtn.target = '_blank';
      matchBtn.title = 'Матчинг';
      adminPanel.appendChild(matchBtn);
    }

    if (pageData.ispGpt) {
      const gptBtn = document.createElement('a');
      gptBtn.setAttribute('class', 'btn grey gpt');
      gptBtn.href = pageData.ispGpt;
      gptBtn.target = '_blank';
      gptBtn.title = 'GPT';
      adminPanel.appendChild(gptBtn);
    }

    body.appendChild(adminPanel);
  }

  return clean;
}

function awaitingLoad() {
  const links = document.links;
  for (let i = 0; i < links.length; i++) {
    let lparts = links[i].href.split('/out/');

    if (lparts.length === 2 && links[i].title.length) {
      const editBtn = document.createElement('a');
      editBtn.setAttribute('class', 'btn red edit incard');
      editBtn.target = '_blank';
      editBtn.title = 'Редактировать';
      editBtn.href =
        'https://isp2.mageo.ru/index.php?route=app/products/matching&id=1&search=' + links[i].title;
      links[i].appendChild(editBtn);
    }

    lparts = links[i].href.split('item-');

    if (lparts.length === 2) {
      const editBtn = document.createElement('a');
      editBtn.setAttribute('class', 'btn yellow edit incard');
      editBtn.target = '_blank';
      editBtn.title = 'Редактировать';
      editBtn.href = 'https://isp2.mageo.ru/index.php?route=app/products/edit&slug=' + lparts[1];
      links[i].appendChild(editBtn);

      const matchBtn = document.createElement('a');
      matchBtn.setAttribute('class', 'btn blue match incard-two');
      matchBtn.target = '_blank';
      matchBtn.title = 'Матчинг';
      matchBtn.href =
        'https://isp2.mageo.ru/index.php?route=app/products/matching&slug=' + lparts[1];
      links[i].appendChild(matchBtn);
    }
  }
}

function getPageData() {
  const url = location.pathname;

  let parts = url.split('item-');
  if (parts.length === 2) {
    return {
      type: 'item',
      ispEdit: 'https://isp2.mageo.ru/index.php?route=app/products/edit&slug=' + parts[1],
      ispMatch: 'https://isp2.mageo.ru/index.php?route=app/products/matching&slug=' + parts[1],
      ispGpt: 'https://isp2.mageo.ru/index.php?route=app/products/chatgpt&slug=' + parts[1],
    };
  }

  parts = url.split('category-');
  if (parts.length === 2) {
    return {
      type: 'category',
      ispEdit: 'https://isp2.mageo.ru/index.php?route=app/catalog/edit&slug=' + parts[1],
      ispMatch: 'https://isp2.mageo.ru/index.php?route=app/catalog/matching&slug=' + parts[1],
      ispGpt: 'https://isp2.mageo.ru/index.php?route=app/catalog/chatgpt&slug=' + parts[1],
    };
  }

  parts = url.split('article-');
  if (parts.length === 2) {
    return {
      type: 'article',
      ispEdit: 'https://isp2.mageo.ru/index.php?route=app/articles/edit&slug=' + parts[1],
      ispMatch: '',
      ispGpt: '',
    };
  }

  return { type: '', ispEdit: '', ispMatch: '', ispGpt: '' };
}
