import { AnyAction } from 'redux';

import { RegisterBody } from '../../api/register';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, RegisterState } from './types';

const initialState: RegisterState = {
  isRequested: false,
  registerModalOpen: false,
};

export const register = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.TOGGLE_REGISTER_MODAL}`: {
      return { ...state, registerModalOpen: action.payload };
    }
    case `${actionTypes.POST_REGISTER}`: {
      return {
        ...state,
        isRequested: true,
        requestBody: action.payload,
        responseBody: undefined,
        errors: undefined,
      };
    }
    case `${actionTypes.POST_REGISTER}${SUCCESS}`: {
      return { ...state, isRequested: false, responseBody: action.payload, errors: undefined };
    }
    case `${actionTypes.POST_REGISTER}${ERROR}`: {
      return { ...state, isRequested: false, responseBody: undefined, errors: action.payload };
    }
    default:
      return state;
  }
};

export const postRegister = (body: RegisterBody) => ({
  type: `${actionTypes.POST_REGISTER}`,
  payload: body,
});

export const toggleRegisterModal = (open: boolean) => ({
  type: `${actionTypes.TOGGLE_REGISTER_MODAL}`,
  payload: open,
});
