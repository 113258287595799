import React from 'react';

import Arrow from '../../assets/images/Arrow.svg';
import { ButtonIconType } from '../ButtonIcon/types';

import { Button } from './styles';

import './styles.css';

type Props = ButtonIconType;
export const ButtonCategoryArrow = ({
  name,
  onClick,
  icon,
  id,
  className,
  children,
  isOpenArrow,
}: Props) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e, id);
    }
  };

  return (
    <>
      <Button onClick={handleClick} className={className} itemProp='item'>
        <div className='iconCategory' itemProp='name'>
          {icon}
        </div>
        <p itemProp='name'>{name}</p>
        <div className={isOpenArrow ? 'iconArrow open' : 'iconArrow'}>
          <Arrow />
        </div>
      </Button>
      {children && children}
    </>
  );
};
