import { AnyAction } from 'redux';

import { CompareBody, CompareType } from '../../api/compare';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, CompareState } from './types';

const initialState: CompareState = {
  isRequested: false,
  data: undefined,
  count: undefined,
};

export const compare = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_COMPARE}`: {
      return { ...state, isRequested: true, requestBody: undefined };
    }
    case `${actionTypes.GET_COMPARE}${SUCCESS}`: {
      return {
        ...state,
        isRequested: false,
        data: action.payload as CompareType,
        count: action.payload.reduce((count, category) => count + category.products.length, 0),
      };
    }
    case `${actionTypes.DELETE_COMPARE}`:
      let { data } = state;
      data = data
        ?.map((category) => {
          let { products } = category;
          if (products && products.length) {
            products = products.filter((item) => item.id !== action.payload.id);
          }
          return { ...category, products };
        })
        .filter((category) => category.products.length);
      return { ...state, isRequested: true, requestBody: action.payload, data };
    case `${actionTypes.POST_COMPARE}`: {
      return { ...state, isRequested: true, requestBody: action.payload };
    }
    case `${actionTypes.DELETE_COMPARE}${SUCCESS}`: {
      let { count } = state;
      if (typeof count === 'number') {
        count--;
      }
      return { ...state, isRequested: false, count };
    }
    case `${actionTypes.POST_COMPARE}${SUCCESS}`: {
      let { count } = state;
      if (typeof count === 'number') {
        count++;
      }
      return { ...state, isRequested: false, count };
    }
    case `${actionTypes.GET_COMPARE}${ERROR}`:
    case `${actionTypes.DELETE_COMPARE}${ERROR}`:
    case `${actionTypes.POST_COMPARE}${ERROR}`: {
      return { ...state, isRequested: false };
    }
    default:
      return state;
  }
};

export const getCompare = () => ({
  type: `${actionTypes.GET_COMPARE}`,
});
export const postCompare = (body: CompareBody) => ({
  type: `${actionTypes.POST_COMPARE}`,
  payload: body,
});

export const deleteCompare = (body: Omit<CompareBody, 'type'>) => ({
  type: `${actionTypes.DELETE_COMPARE}`,
  payload: body,
});
