import styled from 'styled-components';
export const LoggedUserMenu = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 32px;
`;

export const HeaderWrapper = styled.ul`
  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  gap: 32px;
  width: 100%;
  list-style-type: none;
  padding: 0;
  align-items: center;

  @media screen and (max-width: 1050px) {
  }
`;
