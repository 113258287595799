import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = {
  searchText: null,
  result: undefined,
};

export const filters = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.SEARCH_BY_TYPE}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const searchByType = (value: string) => ({
  type: `${actionTypes.SEARCH_BY_TYPE}`,
  payload: value,
});
