import { call, put, takeEvery } from 'redux-saga/effects';

import { deletePriceTracking, postPriceTracking } from '../../api/pricetracking';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* postPriceTrackingSaga(action) {
  try {
    const data = yield call(postPriceTracking, action.payload);
    yield put({ type: actionTypes.POST_PRICETRACKING + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_PRICETRACKING + ERROR, payload: error });
  }
}

export function* deletePriceTrackingSaga(action) {
  try {
    const data = yield call(deletePriceTracking, action.payload);
    yield put({ type: actionTypes.DELETE_PRICETRACKING + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_PRICETRACKING + ERROR, payload: error });
  }
}
export function* watchPriceTrackingSaga() {
  yield takeEvery(actionTypes.POST_PRICETRACKING, postPriceTrackingSaga);
  yield takeEvery(actionTypes.DELETE_PRICETRACKING, deletePriceTrackingSaga);
}
