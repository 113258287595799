import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { selectIdentity } from '../../reducers/identity/selectors';

import { StyledAvatar } from './styles';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  type?: 'line' | 'default';
}

export const Avatar = ({ className, type = 'default', onClick }: Props) => {
  const identity = useSelector(selectIdentity);
  const classes = classNames(className, { line: type === 'line' });

  if (!identity.isAuthorised) return null;
  return (
    <StyledAvatar className={classes} onClick={onClick}>
      {identity.avatarLetters}
    </StyledAvatar>
  );
};
