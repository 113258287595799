import axios from 'axios';
// import { store } from 'store';
import Storage from 'services/storages/local';

const headers = {
  'X-Requested-With': 'XMLHttpRequest', // указываем что это ajax(нужно для бэка)
};

export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL;
};
export const getAppHost = () => {
  return process.env.REACT_APP_HOST;
};

function getToken() {
  const tokenFromLS = Storage.shared().getItem({
    path: ['identity', 'authorization', 'token'],
  });
  // if (store) {
  //   return store.getState()?.identity?.authorization?.token;
  // }
  return tokenFromLS;
}

export const mageoGet = (url: string) => {
  const token = getToken();
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return axios
    .get(`${getApiUrl()}${url}`, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const mageoPost = ({
  url,
  body,
  headers: addHeaders = {},
}: {
  url: string;
  body: object;
  headers?: object;
}) => {
  const token = getToken();

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return axios
    .post(`${getApiUrl()}${url}`, body, {
      headers: { ...headers, ...addHeaders },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const mageoDelete = ({
  url,
  headers: addHeaders = {},
}: {
  url: string;
  headers?: object;
}) => {
  const token = getToken();

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return axios
    .delete(`${getApiUrl()}${url}`, {
      headers: { ...headers, ...addHeaders },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
