import { call, put, takeEvery } from 'redux-saga/effects';

import { getProductItem } from '../../api/productItem';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getProductItemSaga(action) {
  try {
    const data = yield call(getProductItem, action.itemId);
    yield put({ type: actionTypes.GET_PRODUCT_ITEM + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_ITEM + ERROR, payload: error.response });
  }
}

export function* watchProductItemSaga() {
  yield takeEvery(actionTypes.GET_PRODUCT_ITEM, getProductItemSaga);
}
