import React, { useCallback, useMemo, useState } from 'react';
import ClassNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Eye from '../../../assets/images/btnIcons/eye.svg';
import { Button } from '../../../components/Button';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { Input } from '../../../components/Input';
import { Loader } from '../../../components/Loader';
import { Modal } from '../../../components/Modal';
import { Text } from '../../../components/Text';
import { postRegister, toggleRegisterModal } from '../../../reducers/register';
import { selectRegisterData } from '../../../reducers/register/selectors';

import { SocialProvidersButtons } from './SocialProvidersButtons';
import {
  Divider,
  DividerText,
  ErrorMsg,
  FooterText,
  PasswordWrapper,
  RegisterViaEmailForm,
  WrapperBtn,
} from './styles';
import { RegisterType } from './types';

type Props = RegisterType;
export const Register = ({ isOpen }: Props) => {
  const { t } = useTranslation<string>();
  const dispatch = useDispatch();
  const [emailRegister, setEmailRegister] = useState(false);
  const { errors, isRequested } = useSelector(selectRegisterData);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    passwordSec: '',
    name: '',
  });
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isValidForm, setIsValidForm] = useState(true);
  const registerViaEmailClassNames = ClassNames({
    valid: isValidForm,
    invalid: !isValidForm,
  });

  const onRegisterSubmit = useCallback(
    (data) => {
      // dispatch(toggleRegisterModal(false));
      dispatch(postRegister(data));
    },
    [dispatch],
  );

  const onToggleTypeClick = useCallback((e) => {
    e.preventDefault();
    setEmailRegister((prev) => !prev);
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      const newState = {
        ...prevState,
      };
      newState[name] = value;
      return newState;
    });
  }, []);

  const togglePassword = useCallback((e) => {
    e.preventDefault();
    setIsPasswordHidden((prev) => !prev);
  }, []);

  const handleRegisterViaEmailSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (formData.password !== formData.passwordSec) {
        setIsValidForm(false);
      } else {
        onRegisterSubmit(formData);
      }
    },
    [formData, onRegisterSubmit],
  );

  const registerModalClose = () => {
    dispatch(toggleRegisterModal(false));
  };

  const Content = useMemo(() => {
    if (!emailRegister) {
      return (
        <WrapperBtn>
          <SocialProvidersButtons />
          <Divider>
            <DividerText>
              <Text.Text14>{t('misc.or')}</Text.Text14>
            </DividerText>
          </Divider>
          <Button
            className='enterBtn'
            onClick={onToggleTypeClick}
            name={t('registerViaEmail')}
          ></Button>
        </WrapperBtn>
      );
    }
    const emailPlaceholder = t('email') ? t('email') : undefined;
    const namePlaceholder = t('name') ? t('name') : undefined;
    const passwordPlaceholder = t('password') ? t('password') : undefined;
    const passwordSecPlaceholder = t('passwordSec') ? t('passwordSec') : undefined;
    return (
      <WrapperBtn>
        <RegisterViaEmailForm
          onSubmit={handleRegisterViaEmailSubmit}
          className={registerViaEmailClassNames}
        >
          <Input
            name='email'
            placeholder={emailPlaceholder}
            value={formData.email}
            onChange={handleInputChange}
            required={true}
            inputMode='email'
          />
          {errors?.email && <ErrorMsg>{errors?.email}</ErrorMsg>}
          <Input
            name='name'
            placeholder={namePlaceholder}
            value={formData.name}
            onChange={handleInputChange}
            required={true}
            inputMode='text'
          />
          {errors?.name && <ErrorMsg>{errors?.name}</ErrorMsg>}
          <PasswordWrapper>
            <Input
              name='password'
              type={isPasswordHidden ? 'password' : 'text'}
              placeholder={passwordPlaceholder}
              value={formData.password}
              onChange={handleInputChange}
              required={true}
              inputMode='text'
            />
            <ButtonIcon
              type='button'
              className='togglePassword'
              size={'s'}
              icon={<Eye />}
              onClick={togglePassword}
            />
          </PasswordWrapper>
          {errors?.password && <ErrorMsg>{errors?.password}</ErrorMsg>}
          <PasswordWrapper>
            <Input
              name='passwordSec'
              type={isPasswordHidden ? 'password' : 'text'}
              placeholder={passwordSecPlaceholder}
              value={formData.passwordSec}
              onChange={handleInputChange}
              required={true}
              inputMode='text'
            />
            <ButtonIcon
              type='button'
              className='togglePassword'
              size={'s'}
              icon={<Eye />}
              onClick={togglePassword}
            />
          </PasswordWrapper>
          {errors?.passwordSec && <ErrorMsg>{errors?.passwordSec}</ErrorMsg>}
          <WrapperBtn>
            <Button type='submit' className='enterBtn' name={t('makeRegister')}></Button>
          </WrapperBtn>
          {isRequested && <Loader size='small' variant='relative' />}
        </RegisterViaEmailForm>
        <Divider>
          <DividerText>
            <Text.Text14>{t('misc.or')}</Text.Text14>
          </DividerText>
        </Divider>
        <Button
          className='enterBtn'
          onClick={onToggleTypeClick}
          name={t('registerViaSocial')}
        ></Button>
      </WrapperBtn>
    );
  }, [
    emailRegister,
    errors?.email,
    errors?.name,
    errors?.password,
    errors?.passwordSec,
    formData.email,
    formData.name,
    formData.password,
    formData.passwordSec,
    handleInputChange,
    handleRegisterViaEmailSubmit,
    isPasswordHidden,
    isRequested,
    onToggleTypeClick,
    registerViaEmailClassNames,
    t,
    togglePassword,
  ]);

  return (
    <Modal
      title={'Добро пожаловать'}
      isOpen={isOpen}
      description={<Text.Text14>{t('registerModalDescription')}</Text.Text14>}
      onClose={registerModalClose}
      footer={
        <FooterText>
          Продолжая, вы принимаете наши Условия и положения , Политику конфиденциальности и Политику
          использования файлов cookie.
        </FooterText>
      }
    >
      {Content}
    </Modal>
  );
};
