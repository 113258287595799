import { combineReducers } from 'redux';

import { articles } from './articles';
import { categoriesBySearch } from './categoriesBySearch';
import { category } from './category';
import { compare } from './compare';
import { complaint } from './complaint';
import { contactUs } from './contactUs';
import { favorite } from './favorite';
import { filters } from './filters';
import { forgotPassword } from './forgotPassword';
import { identity } from './identity';
import { loaderReducer } from './loader';
import { login } from './login';
import { menu } from './menu';
import { pricetracking } from './pricetracking';
import { productItem } from './productItem';
import { products } from './products';
import { productsHomePage } from './productsHomePage';
import { recentlyViewedProducts } from './recentlyViewedProducts';
import { register } from './register';
import { resetPassword } from './resetPassword';
import { search } from './search';
import { similarProducts } from './similarProducts';
import { socialProviders } from './socialProviders';
import { subscribe } from './subscribe';

export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';

export const rootReducer = combineReducers({
  filters,
  products,
  productsHomePage,
  menu,
  category,
  login,
  identity,
  productItem,
  favorite,
  articles,
  search,
  register,
  loaderReducer,
  compare,
  complaint,
  pricetracking,
  socialProviders,
  resetPassword,
  forgotPassword,
  categoriesBySearch,
  contactUs,
  subscribe,
  recentlyViewedProducts,
  similarProducts,
});

export type RootState = ReturnType<typeof rootReducer>;
