import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = {
  products: {
    discount: null,
    popular: null,
    accessories: null,
  },
  popularQueries: null,
};

export const products = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_PRODUCT_PAGE}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    case `${actionTypes.GET_POPULAR_QUERIES}${SUCCESS}`: {
      return { ...state, popularQueries: action.payload };
    }
    default:
      return state;
  }
};

export const getProducts = () => ({
  type: `${actionTypes.GET_PRODUCT_PAGE}`,
});

export const getPopularQueries = () => ({
  type: `${actionTypes.GET_POPULAR_QUERIES}`,
});
