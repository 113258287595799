import { RefObject, useLayoutEffect, useState } from 'react';
export const useRefDimensions = (ref: RefObject<HTMLElement>) => {
  // For browsers without ResizeObserver dimensions will be forever 0
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    const element = ref.current;
    if (!element || !window.ResizeObserver) return;
    const observer = new window.ResizeObserver(() => {
      const { width, height } = element.getBoundingClientRect();
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return dimensions;
};
