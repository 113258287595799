import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import { deleteFavorite, getFavorite, postFavorite } from '../../api/favorite';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getFavoriteSaga() {
  try {
    const data = yield call(getFavorite);
    yield put({ type: actionTypes.GET_FAVORITE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_FAVORITE + ERROR, payload: error });
  }
}
export function* postFavoriteSaga(action) {
  try {
    const data = yield call(postFavorite, action.payload);
    yield put({ type: actionTypes.POST_FAVORITE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_FAVORITE + ERROR, payload: error });
  }
}

export function* deleteFavoriteSaga(action) {
  try {
    yield call(deleteFavorite, action.payload);
    yield put({ type: actionTypes.DELETE_FAVORITE + SUCCESS, payload: action.payload });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_FAVORITE + ERROR, payload: error });
  }
}
export function* watchFavoriteSaga() {
  yield takeLeading(actionTypes.GET_FAVORITE, getFavoriteSaga);
  yield takeEvery(actionTypes.POST_FAVORITE, postFavoriteSaga);
  yield takeEvery(actionTypes.DELETE_FAVORITE, deleteFavoriteSaga);
}
