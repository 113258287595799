import styled from 'styled-components';

export const TooltipWrapp = styled.div`
  position: relative;

  svg {
    color: var(--text-color);
    background: transparent;
  }
`;

export const TooltipBody = styled.div`
  position: absolute;
  left: -10px;
  bottom: 37px;
  display: flex;
  width: max-content;
  max-width: 231px;
  gap: var(--font-s-1);
  flex-direction: column;
  padding: ${(props) => props.theme.size.size4}px;
  border-radius: ${(props) => props.theme.size.size2}px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  z-index: 100;
`;

export const TooltipContent = styled.p`
  font-size: 12px;
  font-weight: var(--weight-regular);
  font-family: ${(props) => props.theme.fontFamily.Intel}
  line-height: 14.52px;
  color: var(--text-color);
`;

export const TooltipTitle = styled.span`
  font-weight: var(--weight-semibold);
  font-family: ${(props) => props.theme.fontFamily.Intel}
  font-size: 12px;
  line-height: 14.52px;
  color: var(--text-color);
`;

export const TooltipArrow = styled.span`
  position: absolute;
  left: 16px;
  bottom: -16px;
  line-height: 0;

  svg {
    transform: rotate(180deg);
    color: ${(props) => props.theme.colors.white};
  }
`;
