import { call, put, takeEvery } from 'redux-saga/effects';

import { subscribe } from '../../api/subscribe';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* postSubscribeSaga(action) {
  try {
    const data = yield call(subscribe, action.payload);
    yield put({ type: actionTypes.POST_SUBSCRIBE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_SUBSCRIBE + ERROR, payload: error });
  }
}

export function* watchSubscribeSaga() {
  yield takeEvery(actionTypes.POST_SUBSCRIBE, postSubscribeSaga);
}
