import styled, { css } from 'styled-components';

interface TitleProps {
  $fontSize?: string;
}

const titleStyles = css<TitleProps>`
  font-weight: 700;
  font-size: ${(props) => props.$fontSize || '40px'};
  color: var(--text-color);
`;
export const H1 = styled.h1`
  ${titleStyles};
  margin-top: 4px;
  margin-bottom: 16px;
`;
export const H2 = styled.div.attrs(() => ({
  className: 'h2',
}))`
  ${titleStyles};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const H4 = styled.div.attrs(() => ({
  className: 'h4',
}))`
  ${titleStyles};
  font-weight: 600;
  font-size: 22px;
`;

export const H5 = styled.div.attrs(() => ({
  className: 'h5',
}))`
  ${titleStyles};
  font-weight: 700;
  font-size: 16px;
`;
