import {
  ResetPasswordBody,
  ResetPasswordDataType,
  ResetPasswordErrorsType,
} from '../../api/resetPassword';

export const actionTypes = {
  POST_RESET_PASSWORD: 'POST_RESET_PASSWORD',
};

export type ResetPasswordState = {
  isRequested: boolean;
  requestBody?: ResetPasswordBody;
  responseBody?: ResetPasswordDataType;
  errors?: ResetPasswordErrorsType;
};
