import { all } from 'redux-saga/effects';

import { watchGetArticleSaga, watchGetArticlesSaga, watchGetRubricsSaga } from './articles/sagas';
import { watchGetCategoriesBySearchSaga } from './categoriesBySearch/sagas';
import { watchCategoryFacetsSaga, watchCategorySaga } from './category/sagas';
import { watchCompareSaga } from './compare/sagas';
import { watchComplaintSaga } from './complaint/sagas';
import { watchContactUsSaga } from './contactUs/sagas';
import { watchFavoriteSaga } from './favorite/sagas';
import { watchSearchByTypeSaga } from './filters/sagas';
import { watchForgotPasswordSaga } from './forgotPassword/sagas';
import { watchLoginSaga } from './login/sagas';
import { watchGetMenuSaga } from './menu/sagas';
import { watchPriceTrackingSaga } from './pricetracking/sagas';
import { watchProductItemSaga } from './productItem/sagas';
import { watchGetPopularQueriesSaga } from './products/sagas';
import { watchGetHomePageProductsSaga } from './productsHomePage/sagas';
import { watchGetRecentlyViewedProductsSaga } from './recentlyViewedProducts/sagas';
import { watchRegisterSaga } from './register/sagas';
import { watchResetPasswordSaga } from './resetPassword/sagas';
import { watchSearchSaga } from './search/sagas';
import { watchGetSimilarProductsSaga } from './similarProducts/sagas';
import { watchSocialProvidersSaga } from './socialProviders/sagas';
import { watchSubscribeSaga } from './subscribe/sagas';

export default function* rootSaga() {
  yield all([
    watchGetPopularQueriesSaga(),
    watchGetHomePageProductsSaga(),
    watchGetMenuSaga(),
    watchCategorySaga(),
    watchLoginSaga(),
    watchCategoryFacetsSaga(),
    watchProductItemSaga(),
    watchFavoriteSaga(),
    watchSearchByTypeSaga(),
    watchGetArticlesSaga(),
    watchGetArticleSaga(),
    watchGetRubricsSaga(),
    watchSearchSaga(),
    watchRegisterSaga(),
    watchCompareSaga(),
    watchComplaintSaga(),
    watchPriceTrackingSaga(),
    watchSocialProvidersSaga(),
    watchResetPasswordSaga(),
    watchForgotPasswordSaga(),
    watchGetCategoriesBySearchSaga(),
    watchContactUsSaga(),
    watchSubscribeSaga(),
    watchGetRecentlyViewedProductsSaga(),
    watchGetSimilarProductsSaga(),
  ]);
}
