import { mageoGet } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type ItemsSearchType = {
  name: string;
  picture: string;
  price: string | null;
  slug?: string;
};

export type CategoriesSearchType = {
  id: number;
  title: string;
  slug: string;
  link: string;
  picture: string;
};

export type SearchByTypingType = {
  items: ItemsSearchType[];
  categories: CategoriesSearchType[];
};

export function searchByTyping(query: string): Promise<SearchByTypingType> {
  return mageoGet(`${QUERY.searchByType}s?s=${query}`);
}

export const search = (query, range?: string, categoryId?: string) => {
  if (range) {
    return mageoGet(`${QUERY.search}/?s=${query}&${range}`);
  } else if (categoryId) {
    return mageoGet(`${QUERY.search}/?s=${query}&category_id=${categoryId}`);
  }
  return mageoGet(`${QUERY.search}/?s=${query}`);
};
