import { AnyAction } from 'redux';
import Storage from 'services/storages/local';

import { actionTypes, IdentityState } from './types';

const initialState: IdentityState = {
  isAuthorised: false,
  isLoadedFromStorage: false,
  isAdmin: false,
};

export const identity = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.IDENTITY_UPDATE}`: {
      Storage.shared().setItem({
        path: ['identity'],
        value: action.payload,
      });
      return {
        ...state,
        ...action.payload,
        avatarLetters: action.payload.name
          ? `${action.payload.name.trim()[0].toUpperCase()}`
          : action.payload.email
          ? `${action.payload.email.trim()[0].toUpperCase()}`
          : undefined,
      };
    }
    default:
      return state;
  }
};

export const identityUpdate = (payload: IdentityState) => ({
  type: `${actionTypes.IDENTITY_UPDATE}`,
  payload,
});
export const identityLogout = () => ({
  type: `${actionTypes.IDENTITY_UPDATE}`,
  payload: {
    isAuthorised: false,
    email: undefined,
    name: undefined,
    avatarLetters: undefined,
    authorization: undefined,
  },
});
