import { ButtonLine, ButtonSWrapper, ButtonWrapper, Text } from './styles';
import { ButtonIconType } from './types';

type Props = ButtonIconType;
export const ButtonIcon = ({
  name,
  className,
  onClick,
  icon,
  id,
  type,
  size = 'xl',
  children,
  href,
}: Props) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e, id);
    }
  };

  switch (size) {
    case 's': {
      return (
        <ButtonSWrapper className={className} onClick={handleClick}>
          {icon}
          {children && children}
        </ButtonSWrapper>
      );
    }
    case 'xl': {
      return (
        <ButtonWrapper onClick={handleClick} href={href} selected={id === type}>
          <div className='svg-wrapper'>{icon}</div>
          {name && <Text>{name}</Text>}
        </ButtonWrapper>
      );
    }
    case 'line': {
      if (typeof icon === 'string') {
        return (
          <ButtonLine
            className={className}
            onClick={handleClick}
            $backgroundIcon={icon}
            title={name ? name : undefined}
          >
            {name && name}
            {children && children}
          </ButtonLine>
        );
      }
      return (
        <ButtonLine className={className} onClick={handleClick} title={name ? name : undefined}>
          {icon}
          {name && name}
          {children && children}
        </ButtonLine>
      );
    }
    default:
      return <button>{children && children}</button>;
  }
};
