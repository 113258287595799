import { AnyAction } from 'redux';

import { ERROR, SUCCESS } from '../index';

import { actionTypes, ProductItemState } from './types';

const initialState: ProductItemState = {
  item: null,
  request: {
    lastDataUpdated: null,
    awaitingResponse: false,
    lastRequestSent: null,
    lastRequestStatus: null,
  },
};

export const productItem = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_PRODUCT_ITEM}`: {
      return {
        ...state,
        request: {
          awaitingResponse: true,
          lastRequestSent: Date.now(),
        },
      };
    }
    case `${actionTypes.GET_PRODUCT_ITEM}${SUCCESS}`: {
      return {
        ...state,
        item: action.payload,
        request: {
          awaitingResponse: false,
          lastRequestStatus: 200,
          lastDataUpdated: Date.now(),
        },
      };
    }
    case `${actionTypes.GET_PRODUCT_ITEM}${ERROR}`: {
      return {
        ...state,
        request: {
          awaitingResponse: false,
          lastRequestStatus: action.payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export const getProductItem = (itemId) => ({
  type: `${actionTypes.GET_PRODUCT_ITEM}`,
  itemId,
});
