import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from '../../../components/Link';
import { getSocialProviders } from '../../../reducers/socialProviders';
import { selectSocialProviders } from '../../../reducers/socialProviders/selectors';
import Storage from '../../../services/storages/local';

export const SocialProvidersButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation<string>();
  const { providers } = useSelector(selectSocialProviders);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !providers && dispatch(getSocialProviders());
  }, [dispatch, providers]);

  const handleClick = useCallback(() => {
    Storage.shared().setItem({
      path: ['redirect', 'relativeUrl'],
      value: window.location.pathname + window.location.hash + window.location.search,
    });
    return true;
  }, []);

  if (!providers) return null;
  return (
    <>
      {providers.map((provider, index) => (
        <Link
          key={index}
          corporateType={provider.name}
          name={t(`socialNetwork.${provider.name}`)}
          onClick={handleClick}
          href={provider.url}
        ></Link>
      ))}
    </>
  );
};
