import { RootState } from '../index';

export function selectFavoriteData(state: RootState) {
  return state.favorite;
}
export function selectFavoriteCount(state: RootState) {
  return state.favorite.count;
}

export function selectFavoriteItems(state: RootState) {
  return state.favorite.data;
}
