import React, { useEffect } from 'react';
import Heart from 'assets/images/btnIcons/heart16.svg';
import { useDispatch, useSelector } from 'react-redux';

import { getFavorite } from '../../reducers/favorite';
import { selectFavoriteCount } from '../../reducers/favorite/selectors';
import { selectIdentity } from '../../reducers/identity/selectors';
import { Link } from '../Link';

import { Count } from './styles';

interface Props extends React.ComponentPropsWithoutRef<'a'> {}

export const FavoritesButton = ({}: Props) => {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const count = useSelector(selectFavoriteCount);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !count && identity.isAuthorised && dispatch(getFavorite());
  }, [dispatch, identity.isAuthorised, count]);

  return (
    <Link title='избранное' className='headerIcon' href='/favorites'>
      <Heart />
      {!!count && <Count key='counter'>{count}</Count>}
    </Link>
  );
};
