import styled from 'styled-components';

export const ScrollToTopButton = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  transition: ${(props) => props.theme.transition.primary};
  background: rgba(255, 255, 255, 0.6);
  border: solid 1px ${(props) => props.theme.colors.greyF5};
  border-radius: 50%;

  svg {
    color: ${(props) => props.theme.colors.dark};
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    transition: ${(props) => props.theme.transition.primary};
    box-shadow: 0 4px 20px 0 #93aebf1a;
  }
`;
