import { AnyAction } from 'redux';

import { ERROR, SUCCESS } from '../index';

import { actionTypes, FacetsSagaType } from './types';

const initialState = {
  breadcrumbs: null,
  facets: null,
  items: null,
  pagination: null,
  price: null,
  title: null,
  info: null,
  popularQueries: null,
  brands: null,
  errors: null,
};

export const category = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_CATEGORY}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    case `${actionTypes.GET_CATEGORY}${ERROR}`: {
      return { ...state, errors: action.payload.response };
    }
    case `${actionTypes.GET_CATEGORY_WITH_FACETS}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    case `${actionTypes.GET_CATEGORY_WITH_FACETS}${ERROR}`: {
      return { ...state, errors: action.payload.response };
    }
    default:
      return state;
  }
};

export const getCategory = (id) => ({
  type: `${actionTypes.GET_CATEGORY}`,
  payload: id,
});

export const getCategoryWithFacets = (data: FacetsSagaType) => ({
  type: `${actionTypes.GET_CATEGORY_WITH_FACETS}`,
  payload: data,
});
