import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';

import { getHomePageProducts, getPopularQueries } from '../../api/product';
import { ERROR, SUCCESS } from '../index';
import { hideLoader, showLoader } from '../loader';

import { actionTypes } from './types';

export function* getHomePageProductsSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getHomePageProducts);
    yield put({ type: actionTypes.GET_PRODUCT_PAGE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_PAGE + ERROR, payload: error });
  } finally {
    yield put(hideLoader());
  }
}

export function* watchGetHomePageProductsSaga() {
  yield takeLatest(actionTypes.GET_PRODUCT_PAGE, getHomePageProductsSaga);
}

export function* getPopularQueriesSaga() {
  try {
    const data = yield call(getPopularQueries);
    yield put({ type: actionTypes.GET_POPULAR_QUERIES + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_POPULAR_QUERIES + ERROR, payload: error });
  }
}

export function* watchGetPopularQueriesSaga() {
  yield takeLeading(actionTypes.GET_POPULAR_QUERIES, getPopularQueriesSaga);
}
