// import IconCity from '../../assets/images/city.svg';
import Logo from '../../assets/images/logo.svg';
import { LinkType } from '../../components/Link/types';

export const menuConfig: LinkType[] = [
  // { name: 'Смартфоны', href: '/category-73' },
  // { name: 'Планшеты', href: '/category-62' },
  // { name: 'Ноутбуки', href: '/category-25' },
  // { name: 'Смарт-часы', href: '/category-26' },
];

export const navConfig: LinkType[] = [
  { image: <Logo />, href: '/' },
  // { image: <IconCity />, href: '/', name: 'Москва', className: 'itemCity' },
];
