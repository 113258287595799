import { AnyAction } from 'redux';

import { ERROR, SUCCESS } from '../index';

import { actionTypes, SubscribeState } from './types';

const initialState: SubscribeState = {
  isRequested: false,
};

export const subscribe = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.POST_SUBSCRIBE}`: {
      return {
        isRequested: true,
        success: undefined,
      };
    }
    case `${actionTypes.POST_SUBSCRIBE}${SUCCESS}`: {
      return {
        ...state,
        isRequested: false,
        success: true,
      };
    }
    case `${actionTypes.POST_SUBSCRIBE}${ERROR}`: {
      return {
        ...state,
        isRequested: false,
        success: false,
      };
    }
    default:
      return state;
  }
};

export const postSubscribe = (email: string) => ({
  type: `${actionTypes.POST_SUBSCRIBE}`,
  payload: email,
});
