import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = {
  blocks: {
    promo_1: null,
  },
  sliders: {
    first_block: null,
    second_block: null,
    third_block: null,
    fourth_block_bg1: null,
    fifth_block_bg2: null,
  },
};

export const productsHomePage = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_HOME_PAGE_PRODUCTS}${SUCCESS}`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const getHomePageProducts = () => ({
  type: `${actionTypes.GET_HOME_PAGE_PRODUCTS}`,
});
