import { mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type ForgotPasswordDataType = {
  success?: Boolean;
};

export type ForgotPasswordErrorType = {
  message: string;
  errors: ForgotPasswordErrorsType;
};

export type ForgotPasswordErrorsType = {
  [key in keyof ForgotPasswordBody]: string[];
};

export type ForgotPasswordMetaType = {
  include: [];
  custom: [];
};

export type ForgotPasswordType = {
  data: ForgotPasswordDataType;
  meta: ForgotPasswordMetaType;
};

export type ForgotPasswordBody = { email: string };

export function postForgotPassword(body: ForgotPasswordBody): Promise<ForgotPasswordDataType> {
  return mageoPost({
    url: QUERY.postForgotPassword,
    body,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  })
    .catch<ForgotPasswordErrorType>(({ response }) => {
      return Promise.reject(response?.data);
    })
    .then((response: ForgotPasswordType) => response.data);
}
