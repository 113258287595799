import { call, put, takeLatest } from 'redux-saga/effects';

import { getDataCategory } from '../../api/category';
import { ERROR, SUCCESS } from '../index';
import { hideLoader, showLoader } from '../loader';

import { actionTypes } from './types';

export function* getCategorySaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getDataCategory, action.payload);
    yield put({ type: actionTypes.GET_CATEGORY + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_CATEGORY + ERROR, payload: error });
  } finally {
    yield put(hideLoader());
  }
}

export function* watchCategorySaga() {
  yield takeLatest(actionTypes.GET_CATEGORY, getCategorySaga);
}

export function* getCategoryFacetsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getDataCategory, action.payload.id, action.payload?.queryString);
    yield put({ type: actionTypes.GET_CATEGORY_WITH_FACETS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_CATEGORY_WITH_FACETS + ERROR, payload: error });
  } finally {
    yield put(hideLoader());
  }
}

export function* watchCategoryFacetsSaga() {
  yield takeLatest(actionTypes.GET_CATEGORY_WITH_FACETS, getCategoryFacetsSaga);
}
