import { call, put, takeLatest } from 'redux-saga/effects';

import { search } from '../../api/search';
import { ERROR, SUCCESS } from '../index';
import { hideLoader, showLoader } from '../loader';

import { actionTypes } from './types';

export function* getSearchSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(
      search,
      action.payload?.searchQuery,
      action.payload?.query,
      action.payload?.categoryId,
    );
    yield put({ type: actionTypes.GET_SEARCH + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_SEARCH + ERROR, payload: error });
  } finally {
    yield put(hideLoader());
  }
}

export function* watchSearchSaga() {
  yield takeLatest(actionTypes.GET_SEARCH, getSearchSaga);
}
