import { mageoGet } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

type ModificationsType = Array<{
  id: number;
  name: string;
  slug: string;
  active: boolean;
}>;

export type ShopType = {
  name: string;
  description: string;
  logo: string;
  host: string;
};

export type PriceMeta = {
  value: number;
  currency: string;
  from: number;
};

export type SlaveType = {
  id: number;
  name: string;
  description: string;
  picture: string[];
  price: PriceMeta;
  shop: ShopType;
  url: string;
};

export type CharacteristicsType = {
  name: string;
  values: string[];
};

export type AttrType = Array<Array<CharacteristicsType> | { [key: string]: CharacteristicsType }>;

// TODO: change all import for new type ( Product/type component ) and delete this one || 30/01/2024
export type ProductItem = {
  id: number;
  type_of_item: string;
  title: string;
  slug: string;
  category: {
    id: number;
    title: string;
    h1: string | null;
    breadcrumbs: {
      activeClass: string;
      link: string;
      name: string;
    }[];
  };
  description: string;
  pictures: Array<string>;
  rating: number;
  reviews_count: number;
  is_favorite: boolean;
  is_compare: boolean;
  attributes: AttrType;
  accessories: Array<object>;
  modifications: ModificationsType;
  slaves: Array<SlaveType>;
  price: {
    value: number;
    currency: string;
    from: number;
  };
  modification_count: number;
  offers_count: number;
  meta: {
    title: string;
    keywords: string;
    description: string;
    tags: string;
    is_disable_indexing: boolean;
  };
};

export function getProductItem(itemId: string): Promise<ProductItem> {
  return mageoGet(QUERY.getItem + itemId);
}
