import { mageoGet } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type SocialProviders = {
  name: string;
  url: string;
}[];
export function getSocialProviders(): Promise<SocialProviders> {
  return mageoGet(QUERY.getSocialProviders);
}

export const getSocialProviderCallback = (provider, locationSearch) => {
  return mageoGet(`/v1/socials/${provider}/callback${locationSearch}`).then(
    (response) => response.data,
  );
};
