import { mageoDelete, mageoGet, mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';
import { CardType } from '../product';

export type CompareType = {
  id: number;
  name: string;
  count: number;
  properties: {
    id: number;
    name: string;
  }[];
  products: CompareProductType[];
}[];

export interface CompareProductType extends CardType {
  id: number;
  category_id: number;
  name: string;
  pictures: string[];
  slug: string;
  properties: CompareProductPropertyType[];
}

export type CompareProductPropertyType = {
  id: number;
  name: string;
  values: string[];
};

export type CompareBody = { id: number; type: string };

export function getCompare(): Promise<CompareType> {
  return mageoGet(QUERY.getCompare);
}
export function postCompare(body: CompareBody): Promise<{}> {
  const options = {};
  options[`${body.type}_id`] = body.id;
  return mageoPost({
    url: QUERY.postCompare,
    body: options,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteCompare(body: CompareBody): Promise<{}> {
  return mageoDelete({
    url: `${QUERY.deleteCompare}/${body.id}`,
  });
}
