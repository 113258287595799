// @ts-ignore
import compare from 'assets/images/btnIcons/compare16.svg?url';
// @ts-ignore
import excl from 'assets/images/btnIcons/excl.svg?url';
// @ts-ignore
import heart from 'assets/images/btnIcons/heart16.svg?url';
// @ts-ignore
import logout from 'assets/images/btnIcons/logout16.svg?url';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const ListWrapper = styled.ul<{ $type: string }>`
  z-index: 2;

  ${({ $type }) => {
    switch ($type) {
      case 'line': {
        return css`
          position: relative;
          display: flex;
          margin-top: 20px;
          padding: 0;
          width: 100%;
          overflow-x: scroll;

          li {
            &.logout {
              padding-right: 0;
              padding-left: 28px;
              background-position: 8px center;
            }
            &.compare {
              border-left: 1px solid #d9dce9;
              border-right: 1px solid #d9dce9;
              padding-left: 28px;
              background-position: 8px center;
            }
            &.emailConfirm {
              border-right: 1px solid #d9dce9;
              margin-right: 8px;
            }
          }
        `;
      }
      default: {
        return css`
          position: absolute;
          padding: 20px 20px 8px 20px;
          top: 120%;
          right: -20%;
          border: 1px solid #d9dce9;
          box-shadow: 0 6px 15px rgba(111, 127, 137, 0.3);
          background: var(--background);
          border-radius: 10px;
          width: max-content;

          li {
            margin-bottom: 12px;
          }
        `;
      }
    }
  }}
`;

export const ListItemStyled = styled.li`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0 8px 0 20px;
  background: left center no-repeat;
  font-size: 14px;
  font-weight: 500;

  &.logout {
    background-image: url(${logout});
  }
  &.compare {
    background-image: url(${compare});
  }
  &.favorites {
    background-image: url(${heart});
  }
  &.emailConfirm {
    background-image: url(${excl});
    p {
      color: #f00;
    }
  }

  p {
    color: var(--text-color);
    transition: color 0.2s ease-in;
  }

  &:hover p,
  &:focus p {
    color: var(--text-hover-color);
  }
`;
