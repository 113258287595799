import { call, put, takeLeading } from 'redux-saga/effects';

import { getRecentlyViewedProducts } from '../../api/recentlyViewedProducts';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getRecentlyViewedProductsSaga() {
  try {
    const data = yield call(getRecentlyViewedProducts);
    yield put({ type: actionTypes.GET_RECENTLY_VIEWED_PRODUCTS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_RECENTLY_VIEWED_PRODUCTS + ERROR, payload: error });
  }
}

export function* watchGetRecentlyViewedProductsSaga() {
  yield takeLeading(actionTypes.GET_RECENTLY_VIEWED_PRODUCTS, getRecentlyViewedProductsSaga);
}
