import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectIdentity } from '../../reducers/identity/selectors';

import { initAdmin } from './AdminPanel/AdminPanel';

export const MarketingScripts = () => {
  const location = useLocation();
  const { isAdmin } = useSelector(selectIdentity);
  useEffect(() => {
    if (isAdmin) return initAdmin();
  }, [isAdmin, location.key]);
  return null;
};
