import { call, put, takeLeading } from 'redux-saga/effects';

import { getSimilarProducts } from '../../api/similarProducts';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getSimilarProductsSaga(action) {
  try {
    const data = yield call(getSimilarProducts, action.payload.slug);
    yield put({ type: actionTypes.GET_SIMILAR_PRODUCTS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_SIMILAR_PRODUCTS + ERROR, payload: error });
  }
}

export function* watchGetSimilarProductsSaga() {
  yield takeLeading(actionTypes.GET_SIMILAR_PRODUCTS, getSimilarProductsSaga);
}
