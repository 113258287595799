import { mageoDelete, mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type PriceTrackingType = {};

export type PriceTrackingBody = { id: number };

export function postPriceTracking(body: PriceTrackingBody): Promise<PriceTrackingType> {
  return mageoPost({
    url: QUERY.postPriceTracking,
    body: {
      item_id: body.id,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deletePriceTracking(body: PriceTrackingBody): Promise<PriceTrackingType> {
  return mageoDelete({
    url: `${QUERY.deletePriceTracking}/${body.id}`,
  });
}
