import { mageoDelete, mageoGet, mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type FavoriteItem = {
  id: string;
  type_of_item: 'item' | 'slave';
  description: null | string;
  name: string;
  url: string;
  slug: string;
  picture: string;
  min_price: string;
  modification_count: number;
  offers_count: number;
  rating: number;
  reviews_count: number;
  is_favorite: boolean;
  is_compare: boolean;
  price: Object;
  shops: Object[];
  labels: string[];
  modifications: Object[];
};

export type FavoriteType = {
  items?: FavoriteItem[];
};

export type FavoriteBody = { type: string; id: number };

export function postFavorite(body: FavoriteBody): Promise<{}> {
  const options = {};
  options[`${body.type}_id`] = body.id;
  return mageoPost({
    url: QUERY.postFavorite,
    body: options,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteFavorite(body: FavoriteBody): Promise<{}> {
  return mageoDelete({
    url: `${QUERY.deleteFavorite}/${body.type}/${body.id}`,
  });
}
export function getFavorite(): Promise<FavoriteType> {
  return mageoGet(QUERY.getFavorite);
}
