import { mageoGet } from 'api/apiConfig';
import { QUERY } from 'api/apiConfig/constants';

type ArticleItemType = {
  title: string;
  picture: string | null;
  date: string | null;
  description: string;
  slug: string;
  author?: string;
  count_view: number;
  meta: {
    title: string | null;
    keywords: string | null;
    description: string | null;
    tags: string | null;
    noindex: boolean;
  };
  rubrics: RubricType[];
};

export type ArticlesItemType = {
  path?: string;
  id: string;
  title: string;
  picture: string; // | null
  date: string; // | null
  description: string;
  slug: string;
  author?: string;
  count_view: number;
  rubric: {
    title: string;
    id: number;
    slug: string;
  };
};

export type ArticleType = {
  item: ArticleItemType;
};

export type ArticlesType = {
  items: ArticlesItemType[];
  pagination: {
    total: number;
    lastPage: number;
    path: string;
    perPage: number;
    currentPage: number;
  };
  meta: { title: string; description: string; tags: string; noindex: boolean };
};

export type RubricType = {
  id: string;
  title: string;
  slug: string;
  description: string;
  count_articles: number;
  children: Array<RubricType>;
};
export type RubricsType = {
  items: RubricType[];
};

export type ArticlesQueryOptions = {
  rubricId?: number;
  rubricSlug?: string;
  page?: number;
};

export function getArticle(slug: string): Promise<ArticleType> {
  return mageoGet(QUERY.getArticle + slug);
}

export function getArticles(queryOptions: ArticlesQueryOptions): Promise<ArticlesType> {
  let query = '';
  if (queryOptions?.rubricId) query += `?filters[rubric_id]=${queryOptions.rubricId}`;
  let slug = '';
  if (queryOptions?.rubricSlug) slug += `/${queryOptions.rubricSlug}`;
  return mageoGet(`${QUERY.getArticles}${slug}${query}`);
}

export function getRubrics(): Promise<RubricsType> {
  return mageoGet(QUERY.getRubrics);
}
