// eslint-disable no-magic-numbers
export const getDeclension = (number: number, one: string, two: string, five: string): string => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }

  n %= 10;
  if (n === 1) {
    return one;
  }

  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

// eslint-disable-next-line no-magic-numbers
export const numberStringToReadingString = (number: string | number, razryad = 3) => {
  if (typeof number !== 'string') {
    // eslint-disable-next-line no-param-reassign
    number = `${number}`;
  }
  const arr = number.split('').reverse();
  const res: string[] = [];
  let i = 0;
  arr.forEach((char) => {
    res.unshift(char);
    if (++i === razryad) {
      i = 0;
      res.unshift(' ');
    }
  });
  return res.join('');
};
