import styled from 'styled-components';

export const StyledFooter = styled.footer`
  width: 100%;
  background: #333333;
  color: var(--color-white);
  padding: 40px 0;

  &.fixedToTheBottom {
    position: fixed;
    bottom: 0;
  }

  .logo {
    svg {
      margin-top: -8px;

      path:nth-child(3),
      path:nth-child(4) {
        filter: brightness(0) saturate(100%) invert(92%) sepia(4%) saturate(110%) hue-rotate(148deg)
          brightness(106%) contrast(97%);
      }
    }
  }
`;

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 32px;
  margin-bottom: 16px;

  @media screen and (max-width: 960px) {
    grid-template-areas: 'logo logo logo logo' '0 1 2 3';
    grid-template-columns: auto;

    .logo {
      grid-area: logo;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-areas: 'logo logo logo' 'mailto mailto mailto' '0 1 2';

    .mailto {
      grid-area: mailto;
    }
  }

  @media screen and (max-width: 650px) {
    grid-template-areas: 'logo logo' 'mailto mailto' '0 1' '2 2';
  }
`;

export const FooterColumn = styled.div`
  font-size: 14px;

  p {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
  }

  a {
    color: var(--color-white);
    text-align: left;
    line-height: 18px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 8px;
      font-weight: 400;
    }
  }

  ul li a {
    font-weight: 400;
  }
`;

export const FooterLastColumn = styled.div`
  a {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
  }
`;

export const Line = styled.p`
  margin-top: 6px;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  text-align: center;

  a {
    font-weight: 400;
  }
`;
