import { mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type EmailConfirmBody = {
  email: string;
  token: string;
}[];

export const postEmailConfirm = (options: EmailConfirmBody) => {
  return mageoPost({
    url: QUERY.emailConfirm,
    body: options,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response) => response.data)
    .catch((response) => {
      throw response.response.data;
    });
};

export const resendEmailConfirm = () => {
  return mageoPost({
    url: QUERY.sendEmailConfirm,
    body: {},
  })
    .then((response) => response.data)
    .catch((response) => {
      throw response.response.data;
    });
};
