import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 262px;
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0 10px 12px 10px;
  position: relative;
  padding-bottom: 50px;

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 170px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    background: var(--background);
    width: 100%;
    border: 1px solid #d9dce9;
  }

  img {
    width: 132px;
    height: 132px;
    object-fit: unset;
    transition: transform 0.3s;
  }

  img:hover {
    transform: scale(1.1);
  }

  h2,
  .h2 {
    font-weight: 500;
    line-height: 1.1;
    text-align: left;
    cursor: pointer;
    font-size: 17px;
    margin-bottom: 24px;
  }

  a,
  .listShowMore {
    margin-top: 10px;
    line-height: 1.6;
    text-align: left;
    padding-left: 16px;
  }

  .listShowMore {
    cursor: pointer;
    color: #6f7f89;
    margin-top: 30px;
    position: absolute;
    bottom: 0;
  }
`;
