import { mageoPost } from '../apiConfig';
import { QUERY } from '../apiConfig/constants';

export type LoginDataType = {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  token: string;
};

export type LoginErrorType = {
  message: string;
  errors: LoginErrorsType;
};

export type LoginErrorsType = {
  [key in keyof LoginBody]: string[];
};

export type LoginMetaType = {
  include: [];
  custom: [];
};

export type LoginType = {
  data: LoginDataType;
  meta: LoginMetaType;
};

export type LoginBody = { email: string; password: string };

export function postLogin(body: LoginBody): Promise<LoginDataType> {
  return mageoPost({
    url: QUERY.postLogin,
    body,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  })
    .catch<LoginErrorType>(({ response }) => {
      return Promise.reject(response?.data);
    })
    .then((response: LoginType) => response.data);
}
