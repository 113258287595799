import React, { forwardRef, RefObject } from 'react';
import classNames from 'classnames';

import Logo from '../../assets/images/logo.svg';
import { Link } from '../../components/Link';

import { footerConfig } from './config';
import { FooterColumn, FooterContainer, FooterLastColumn, Line, StyledFooter } from './styles';

type Props = {
  fixedToTheBottom: boolean;
};

export const Footer = forwardRef(({ fixedToTheBottom }: Props, ref: RefObject<HTMLElement>) => {
  const footerClassNames = classNames({ fixedToTheBottom });
  return (
    <StyledFooter ref={ref} className={footerClassNames}>
      <FooterContainer className='container'>
        <FooterColumn className='logo'>
          <Logo />
        </FooterColumn>
        {footerConfig.map((item, index) => {
          return (
            <FooterColumn key={`${item.title}-${index}`}>
              <p>{item.title}</p>
              <ul>
                {item.listOfSupportingLinks.map(({ text, href }, idx) => {
                  return (
                    <li key={`${text}-${idx}`}>{href ? <Link name={text} href={href} /> : text}</li>
                  );
                })}
              </ul>
            </FooterColumn>
          );
        })}
        <FooterColumn className='mailto'>
          <p>Быстрая связь</p>
          <FooterLastColumn>
            <Link name='info@mageo.ru' href='mailto:info@mageo.ru' />
            <span>пишите по всем вопросам</span>
          </FooterLastColumn>
        </FooterColumn>
      </FooterContainer>
      <Line>{new Date().getFullYear()} год. Все права защищены.</Line>
      <Line>
        <Link className='white inline' href='/article-politika-konfidencialnosti'>
          Политика компании
        </Link>{' '}
        в отношении обработки персональных данных
      </Line>
    </StyledFooter>
  );
});
Footer.displayName = 'Footer';
