import React, { PropsWithChildren } from 'react';
import { decode } from 'html-entities';
import { Helmet } from 'react-helmet';

import { MetaProps } from './types';

export const MetaHelmet = ({
  title,
  description,
  keywords,
  image,
  children,
}: PropsWithChildren<MetaProps>) => {
  return (
    <Helmet>
      {title && title.trim().length && <title>{decode(title)}</title>}
      {description && description.trim().length && (
        <meta name='description' content={description} />
      )}
      {keywords && keywords.trim().length && <meta name='Keywords' content={keywords} />}
      {title && title.trim().length && <meta property='og:title' content={title} />}
      {description && description.trim().length && (
        <meta property='og:description' content={description} />
      )}
      {image && image.trim().length && <meta property='og:image' content={image} />}
      {children && children}
    </Helmet>
  );
};
