import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Storage from 'services/storages/local';

import { identityUpdate } from '../../reducers/identity';
export const SetIdentityOnWindowLoaded = () => {
  const dispatch = useDispatch();
  const onPageLoad = useCallback(() => {
    const identity =
      Storage.shared().getItem({
        path: ['identity'],
      }) ?? {};
    identity.isLoadedFromStorage = true;
    dispatch(identityUpdate(identity));
  }, [dispatch]);
  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [onPageLoad]);
  return null;
};
