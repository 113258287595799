/* eslint no-magic-numbers: 0 */
import styled from 'styled-components';

// @ts-ignore
import arrowleft from '../../assets/images/btnIcons/arrowleft.svg?url';
// @ts-ignore
import magnifer from '../../assets/images/btnIcons/magnifer.svg?url';
// @ts-ignore
import magnifer16 from '../../assets/images/btnIcons/magnifer16.svg?url';
// @ts-ignore
import magniferRed from '../../assets/images/btnIcons/magniferRed.svg?url';
// @ts-ignore
import close from '../../assets/images/closegray.svg?url';
interface Props {
  $coords: { left: number; top: number; width: number };
}

export const Container = styled.div<Props>`
  width: 100%;
  max-width: 650px;
  font-weight: 400;
  font-size: 14px;

  &.withResults {
    position: fixed;
    width: ${({ $coords }) => `${$coords.width}px`};
    max-width: none;
    top: ${({ $coords }) => `${$coords.top}px`};
    left: ${({ $coords }) => `${$coords.left}px`};
    z-index: 1;

    @media screen and (max-width: 960px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin-left: 0;
      width: auto;
      background: var(--background);
    }
  }

  @media screen and (max-width: 960px) {
    max-width: 466px;
  }
`;

export const InputContainer = styled.div`
  height: 50px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #bfc6ca;
  line-height: 40px;
  padding: 4px;
  display: grid;
  grid-template-columns: max-content auto max-content;
  transition: all 0.2s linear;
  background: var(--background);

  @media screen and (max-width: 960px) {
    height: 36px;
    padding: 2px;
    line-height: 32px;
  }

  @media screen and (max-width: 480px) {
    border-color: transparent;
    .isFocused & {
      border-color: #bfc6ca;
    }
  }

  @media screen and (max-width: 360px) {
    height: 32px;
    line-height: 28px;
  }

  .withResults & {
    @media screen and (max-width: 960px) {
      height: 50px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #d9dce9;
      line-height: 40px;
      padding: 5px;
    }
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
`;

export const SearchButton = styled.label`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #ff2f3c;
  transition: all 0.2s linear;

  @media screen and (max-width: 960px) {
    height: 31px;
    width: 31px;

    .withResults.isFocused & {
      width: 40px;
      height: 40px;
      background: transparent;

      &::after {
        top: 9px;
        left: 9px;
        bottom: 9px;
        right: 9px;
        background-image: url(${magniferRed});
      }

      &:hover {
        &::after {
          filter: none;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    background: transparent;
    .isFocused & {
      background: #ff2f3c;
    }
  }

  @media screen and (max-width: 360px) {
    height: 27px;
    width: 27px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 9px;
    left: 9px;
    bottom: 9px;
    right: 9px;
    background: transparent url(${magnifer}) center center;
    background-size: contain;
    transition: all 0.25s linear;

    @media screen and (max-width: 960px) {
      top: 7px;
      left: 7px;
      bottom: 7px;
      right: 7px;
    }
    @media screen and (max-width: 480px) {
      top: 5px;
      left: 5px;
      bottom: 5px;
      right: 5px;
      background-image: url(${magniferRed});

      .isFocused & {
        top: 7px;
        left: 7px;
        bottom: 7px;
        right: 7px;
        background-image: url(${magnifer});
      }
    }
  }

  &:hover {
    background-color: #eeeef8;

    &::after {
      filter: invert(23%) sepia(20%) saturate(350%) hue-rotate(157deg) brightness(25%) contrast(85%);
    }
  }
`;
export const CloseButton = styled.div`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    background: transparent url(${close}) center center;
    background-size: contain;
  }
`;

export const BackButton = styled.div`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    bottom: 8px;
    right: 8px;
    background: transparent url(${arrowleft}) center center;
    background-size: contain;
  }

  @media screen and (min-width: 960px) {
    display: none;
  }
`;

export const InputSearchStyled = styled.input`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: none;
  opacity: 1;
  transition: all 0.2s linear;
  font-size: 14px;
  font-weight: 400;

  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #bfc6ca;
  }

  @media screen and (max-width: 480px) {
    opacity: 0;
    .isFocused & {
      opacity: 1;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;
export const LeftWrapper = styled.div`
  min-width: 22px;
`;

export const Results = styled.div`
  padding: 16px 16px 46px;
  margin-top: 4px;
  max-height: calc(100vh - 142px);
  overflow: scroll;
  background: var(--background);
  border-radius: 8px;

  @media screen and (max-width: 960px) {
    max-height: calc(100vh - 50px);
  }

  .showAllButton {
    position: absolute;
    width: -moz-available;
    bottom: 8px;
    left: 8px;
    right: 8px;
    display: block;
    line-height: 36px;
    border: 1px solid #bfc6ca;
    text-align: center;
    color: #6f7f89;
    font-size: 14px;
    background: var(--background);
  }
`;
export const ResultsRow = styled.div`
  border-bottom: 1px solid #d9dce9;
  margin-bottom: 16px;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 16px;

  &:last-of-type {
    border: none;
    margin-bottom: 0;
  }

  .resultTitle {
    color: #6f7f89;
    margin-bottom: 13px;
  }

  a {
    display: block;
    text-align: left;
    color: #3b4449;
  }

  .category {
    padding: 0 0 0 24px;
    background: transparent url(${magnifer16}) left center no-repeat;
    margin-bottom: 8px;

    a {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .item {
    display: grid;
    grid-template-columns: 40px auto max-content;
    gap: 10px;
    align-items: center;
    margin-bottom: 16px;

    figure {
      width: 40px;

      img {
        max-height: 48px;
        object-fit: contain;
        max-width: 100%;
      }
    }

    .price {
      font-size: 16px;
      font-weight: 700;
      padding-left: 14px;
    }

    a {
      max-height: 48px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

export const IconWrapper = styled.button`
  background: var(--background);
  border-bottom: 1px solid #d9dce9;
  border-top: 1px solid #d9dce9;
  border-right: 1px solid #d9dce9;
  height: 48px;
  width: 70px;
`;
export const InputWrapper = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;

  ${InputSearchStyled} {
    border-radius: ${({ show }) => (show ? '30px 0px 0 0px' : '30px 0 0 30px')};
  }

  ${IconWrapper} {
    border-radius: ${({ show }) => (show ? '0px 30px 0 0px' : '0 30px 30px 0')};
  }
`;

// export const Container = styled.div<{ iconStart?: boolean; isOpen?: boolean }>`
//   position: relative;
//
//   ${(props) => {
//     if (props.iconStart && !props.isOpen) {
//       return `
//             width: 100%;
//             background-color: var(--background);
//             border-radius: 30px;
//             margin-right: 35px;
//         `;
//     }
//
//     if (props.iconStart && props.isOpen) {
//       return `
//             width: 100%;
//             background-color: var(--background);
//             border-radius: 30px 30px 0 0;
//             margin-right: 35px;
//         `;
//     }
//   }}
// `;
export const IconStart = styled.div`
  margin-left: 18px;
  cursor: auto;
  background: transparent;
  margin-right: 10px;
`;
export const DropdownWrapper = styled.div`
  position: absolute;
  border-bottom: 1px solid #d9dce9;
  border-left: 1px solid #d9dce9;
  border-right: 1px solid #d9dce9;
  background-color: white;
  width: 100%;
  border-radius: 0 0 30px 30px;
  padding: 2px;
  margin-bottom: 6px;
  z-index: 1;
  padding-bottom: 8px;
`;

export const OptionsRow = styled.div<{ focus: boolean }>`
  cursor: pointer;
  margin-top: 4px;
  background-color: ${({ focus }) => (focus ? '#EBF1F4' : '')};

  :hover {
    background-color: #ebf1f4;
    opacity: 10;
  }
`;
