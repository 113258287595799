import { call, put, takeEvery } from 'redux-saga/effects';

import { postResetPassword } from '../../api/resetPassword';
import { identityLogout, identityUpdate } from '../identity';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* postResetPasswordSaga(action) {
  try {
    const data = yield call(postResetPassword, action.payload);
    yield put(identityLogout());
    yield put(
      identityUpdate({
        isAuthorised: true,
        email: action.payload.email,
        authorization: {
          token: action.payload.token,
        },
      }),
    );
    yield put({ type: actionTypes.POST_RESET_PASSWORD + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_RESET_PASSWORD + ERROR, payload: error });
  }
}

export function* watchResetPasswordSaga() {
  yield takeEvery(actionTypes.POST_RESET_PASSWORD, postResetPasswordSaga);
}
