import { AnyAction } from 'redux';

import { ContactUsBody } from '../../api/contactUs';
import { ERROR, SUCCESS } from '../index';

import { actionTypes, ContactUsState } from './types';

const initialState: ContactUsState = {
  isRequested: false,
};

export const contactUs = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.POST_CONTACT_US}`: {
      return {
        isRequested: true,
        requestBody: action.payload,
        responseBody: undefined,
        errors: undefined,
        success: undefined,
      };
    }
    case `${actionTypes.POST_CONTACT_US}${SUCCESS}`: {
      return {
        ...state,
        isRequested: false,
        responseBody: action.payload,
        errors: undefined,
        success: true,
      };
    }
    case `${actionTypes.POST_CONTACT_US}${ERROR}`: {
      return {
        ...state,
        isRequested: false,
        responseBody: undefined,
        errors: action.payload,
        success: false,
      };
    }
    default:
      return state;
  }
};

export const postContactUs = (body: ContactUsBody) => ({
  type: `${actionTypes.POST_CONTACT_US}`,
  payload: body,
});
