import styled from 'styled-components';

export const StyledHeader = styled.header`
  position: relative;
  background: var(--background);
  border-bottom: 1px solid #d9dce9;
  z-index: 2;

  @media screen and (max-width: 960px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  .navigate {
    height: 92px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 960px) {
      height: 60px;
    }

    @media screen and (max-width: 360px) {
      height: 50px;
    }
  }
`;
