import { AnyAction } from 'redux';

import { SocialProviders } from '../../api/socialProviders';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export type SocialProvidersState = {
  isRequested: boolean;
  providers?: SocialProviders;
};

const initialState: SocialProvidersState = {
  isRequested: false,
};

export const socialProviders = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_SOCIAL_PROVIDERS}`: {
      return {
        ...state,
        isRequested: true,
      };
    }
    case `${actionTypes.GET_SOCIAL_PROVIDERS}${SUCCESS}`: {
      return {
        ...state,
        providers: action.payload,
        isRequested: false,
      };
    }
    case `${actionTypes.GET_SOCIAL_PROVIDERS}${ERROR}`: {
      return {
        ...state,
        isRequested: false,
      };
    }
    default:
      return state;
  }
};

export const getSocialProviders = () => ({
  type: `${actionTypes.GET_SOCIAL_PROVIDERS}`,
});
