import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import { deleteCompare, getCompare, postCompare } from '../../api/compare';
import { ERROR, SUCCESS } from '../index';

import { actionTypes } from './types';

export function* getCompareSaga() {
  try {
    const data = yield call(getCompare);
    yield put({ type: actionTypes.GET_COMPARE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_COMPARE + ERROR, payload: error });
  }
}
export function* postCompareSaga(action) {
  try {
    const data = yield call(postCompare, action.payload);
    yield put({ type: actionTypes.POST_COMPARE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_COMPARE + ERROR, payload: error });
  }
}

export function* deleteCompareSaga(action) {
  try {
    const data = yield call(deleteCompare, action.payload);
    yield put({ type: actionTypes.DELETE_COMPARE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_COMPARE + ERROR, payload: error });
  }
}
export function* watchCompareSaga() {
  yield takeEvery(actionTypes.POST_COMPARE, postCompareSaga);
  yield takeEvery(actionTypes.DELETE_COMPARE, deleteCompareSaga);
  yield takeLeading(actionTypes.GET_COMPARE, getCompareSaga);
}
