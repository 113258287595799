import { call, put, takeEvery } from 'redux-saga/effects';

import { postRegister } from '../../api/register';
import { identityUpdate } from '../identity';
import { ERROR, SUCCESS } from '../index';

import { toggleRegisterModal } from './index';
import { actionTypes } from './types';

export function* postRegisterSaga(action) {
  try {
    const data = yield call(postRegister, action.payload);
    yield put(toggleRegisterModal(false));
    yield put(
      identityUpdate({
        isAuthorised: true,
        email: data.email,
        name: data.name,
        authorization: {
          token: data.accessToken,
        },
        role: data.role,
        permissions: data.permissions,
        isAdmin: data.role === 'admin',
        isNeededEmailConfirmation: true,
      }),
    );
    yield put({ type: actionTypes.POST_REGISTER + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.POST_REGISTER + ERROR, payload: error });
  }
}

export function* watchRegisterSaga() {
  yield takeEvery(actionTypes.POST_REGISTER, postRegisterSaga);
}
