import { AnyAction } from 'redux';

import { SUCCESS } from '../index';

import { actionTypes } from './types';

const initialState = {
  category: null,
};

export const categoriesBySearch = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${actionTypes.GET_CATEGORIES_BY_SEARCH}${SUCCESS}`: {
      return { ...state, category: [...action.payload] };
    }
    default:
      return state;
  }
};

export const getCategoriesBySearchResult = (searchQuery: string) => ({
  type: `${actionTypes.GET_CATEGORIES_BY_SEARCH}`,
  payload: { searchQuery },
});
