import React from 'react';
import ReactDOM from 'react-dom/client';
import(/* webpackChunkName: "fonts" */ './fonts');

import App from './App';

import './i18n';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(<App />);
}
