import styled from 'styled-components';

export const WrapperBtn = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
`;

export const FooterLink = styled.a`
  color: #3b4449;
  text-decoration: underline;
  cursor: pointer;
`;

export const Form = styled.form`
  position: relative;
`;

export const PasswordWrapper = styled.div`
  transition: 0.3s linear;
  transition-property: opacity, height;
  height: 64px;
  overflow: hidden;

  &.hide {
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  position: relative;

  .togglePassword {
    position: absolute;
    right: 21px;
    top: 29px;
  }
`;

export const HideWrapper = styled.div`
  transition: 0.3s linear;
  transition-property: opacity, max-height;
  overflow: hidden;
  max-height: max-content;

  &.hide {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }
`;

export const ErrorMsg = styled.p`
  color: red;
  font-size: 12px;
  line-height: 20px;
`;
export const Msg = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: red;
`;

export const Divider = styled.div`
  text-align: center;
  position: relative;
  margin: 10px 0 15px;

  &:before {
    display: block;
    height: 0;
    width: 100%;
    content: '';
    position: absolute;
    top: 50%;
    border-top: 1px solid #d9d9d9;
  }
`;
