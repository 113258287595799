import { PriceTrackingBody } from '../../api/pricetracking';

export const actionTypes = {
  POST_PRICETRACKING: 'POST_PRICETRACKING',
  DELETE_PRICETRACKING: 'DELETE_PRICETRACKING',
};

export type PriceTrackingState = {
  isRequested: boolean;
  requestBody?: PriceTrackingBody;
};
