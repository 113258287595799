import styled, { css } from 'styled-components';

/* eslint-disable no-magic-numbers*/
const mapSize = {
  small: [25, 4],
  normal: [50, 8],
  green: [100, 16],
};

export const Wrapper = styled.div<{
  $variant?: 'relative' | 'fixed';
  $transparent?: boolean;
}>`
  ${(props) => {
    if (props.$variant === 'relative') {
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, ${props.$transparent ? 0 : 0.6});
        z-index: 1;
      `;
    } else if (props.$variant === 'fixed') {
      return css`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, ${props.$transparent ? 0 : 0.6});
        z-index: 1;
      `;
    } else if (!props.$variant) {
      return css`
        position: relative;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      `;
    }
  }}
`;
export const LoaderStyled = styled.div<{
  sizeLoader: 'small' | 'normal' | 'large';
  $variant?: 'relative' | 'fixed';
}>`
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${(props) => `${mapSize[props.sizeLoader][0]}px`};
  height: ${(props) => `${mapSize[props.sizeLoader][0]}px`};
  border-radius: 50%;
  background: conic-gradient(#0000 10%, var(--text-hover-color));
  -webkit-mask: ${(props) =>
    `radial-gradient(farthest-side, #0000 calc(100% - ${mapSize[props.sizeLoader][1]}px), #000 0)`};
  animation: s3 1s infinite linear;

  ${(props) => {
    if (props.$variant === 'relative') {
      return css`
        position: absolute;
      `;
    } else if (props.$variant === 'fixed') {
      return css`
        position: absolute;
      `;
    } else if (!props.$variant) {
      return css`
        position: fixed;
      `;
    }
  }}

  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
`;
